.sec-mv {
	//margin-bottom: 144px;
	position: relative;
	width: 100%;
	max-width: 1550px;
	margin: 0 auto 144px;
	//padding-top: 71.9vh;
	//@include mq(mac) {
	//	padding-top: 76vh;
	//}
	//@include mq(xl) {
	//	padding-top: 100vh;
	//}
	@include mq {
		margin-bottom: 59px;
	}
}
.mv {
	&-ttl {
		font-family: $font-PhosphateSolid;
		//@include font-size(279);
		font-size: 9vw;
		color: #E2231B;
		text-align: center;
		position: relative;
		z-index: 1;
		line-height: 1;
		//letter-spacing: 29px;
		letter-spacing: vw(29);
		//margin: -142px -19px 110px;
		margin: vw(-115) vw(-19) vw(110);
		//@include mq(mac) {
		//	@include font-size(200);
		//	margin: -100px -19px 110px;
		//}
		@include mq(xl) {
			font-size: 15vw;
			letter-spacing: 3vw;
			margin: -8vw -37px 80px;
		}
		@include mq(sm) {
			margin: -9vw -37px 35px;
			font-size: 17.2vw;
			letter-spacing: 2.15vw;
		}
	}
	&-txt {
		@include font-size(40);
		font-weight: bold;
		color: #4B4F54;
		text-align: center;
		position: relative;
		z-index: 1;
		line-height: 2;
		margin-bottom: 33px;
		@include mq(xl) {
			@include font-size(35);
		}
		@include mq {
			@include font-size(24);
			line-height: 1.35;
			margin-bottom: 20px;
			> * {
				margin-bottom: 25px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		@include mq(xs) {
			@include font-size(20);
		}
	}
	&-btn {
		width: 100%;
		max-width: 1280px;
		margin: 0 auto;
		position: relative;
		z-index: 1;
	}
	&-slider {
		//position: absolute;
		//top: 104px;
		//height: calc(100vh - 104px);
		//width: 100vw;
		//top: 0;
		//left: 50%;
		//transform: translateX(-50%);
		//overflow: hidden;
		
		//@include mq(ipd) {
		//	height: calc(100vh - 80px);
		//}
		//@include mq {
		//	height: calc(100vh - 64px);
		//}
		.item {
			outline: none !important;
			img {
				display: block;
				object-fit: cover;
				font-family: 'object-fit: cover;';
				width: 100%;
				height: 100%;
			}
			> * {
				width: 100%;
				height: 100%;
				background-size: cover;
				background-position: center center;
				background-repeat: no-repeat;
			}
		}
		.slick {
			&-list, &-track {
				height: 100%;
			}
			&-dots {
				width: 40px;
				bottom: auto;
				right: 123px;
				top: 50%;
				display: flex;
				flex-wrap: wrap;
				transform: translateY(-50%);
				@include mq(xl) {
					right: 20px;
				}
				@include mq {
					width: 20px;
				}
				li {
					display: block;
					margin: 2px 0 1px;
					@include mq {
						margin: 0px 0 1px;
					}
					button {
						display: block;
						width: 40px;
						height: 40px;
						border: 4px solid transparent;
						border-radius: 50%;
						padding: 0;
						@include mq {
							width: 20px;
							height: 20px;
						}
						&:before {
							border: none;
							width: 12px;
							height: 12px;
							background: #E2231B;
							@include mq {
								width: 6px;
								height: 6px;
							}
						}
					}
					&.slick-active {
						button {
							border: 2px solid #E2231B;
						}
					}
				}
			}
		}
	}
}

.sec-vision {
	margin-bottom: 184px;
	@include mq {
		margin-bottom: 86px;
	}
}


.sec-contact {
	padding: 218px 0 242px;
	&.bg {
		background-image: url("../img/contact/bg.jpg");
		background-repeat: no-repeat;
		background-position: top 121px left -6px;
		background-size: 945px;
		@include mq {
			background-image: url("../img/contact/bg-sp.jpg");
			background-position: top 55px left -4px;
			background-size: 257px;
		}
	}
	@include mq {
		padding: 92px 0 99px;
	}
}
.contact {
	&-ttl {
		margin-bottom: 37px;
		@include mq {
			margin-bottom: 24px;
		}
	}
	&-des {
		margin-bottom: 134px;
		text-align: center;
		@include mq(lp) {
			margin-bottom: 80px;
		}
		@include mq {
			margin-bottom: 29px;
		}
	}
}


.page-index{
	.inner, .inner-1280{
		max-width: 1080px;
	}
}