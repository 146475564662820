.sec-contact-page {
	padding: 127px 0 206px;
	background: url("../img/contact/bg.jpg") no-repeat;
	background-position: top 2% left 0%;
	background-size: 44%;
	@include mq {
		background: url("../img/contact/bg-sp.jpg") no-repeat;
		background-size: 246px;
		background-position: top 8px left 0px;
		padding: 56px 0 64px;
	}
	.inner-1280{
		max-width: 1080px;
	}
}
.contact {
	&-page {
		&__ttl {
			margin-bottom: 8px;
			@include mq {
				@include font-size(60);
				&.style {
					@include font-size(55);
					margin: 0 -27px 8px;
				}
			}
			@include mq(xs){
				@include font-size(40);
			}
		}
		&__des {
			margin-bottom: 148px;
			letter-spacing: 3.6px;
			@include mq {
				letter-spacing: 1.8px;
				margin-bottom: 52px;
			}
		}
	}
	&-step {
		display: flex;
		justify-content: space-between;
		width: 100%;
		max-width: 1200px;
		margin: 0 auto 122px;
		text-align: center;
		font-weight: bold;
		@include font-size(26);
		@include mq(xl) {
			@include font-size(20);
		}
		@include mq {
			@include font-size(12);
			margin: 0 -18px 63px;
			width: calc(100% + 36px);
		}
		&__item {
			border: 3px solid #4B4F54;
			border-radius: 10px;
			width: calc(33.33% - 110px);
			height: 74px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			@include mq(xl) {
				width: calc(33.33% - 50px);
				height: 55px;
			}
			@include mq {
				width: calc(33.33% - 19px);
				height: 27px;
				border-width: 1px;
				border-radius: 5px;
			}
			&:after {
				position: absolute;
				content: '';
				height: 2px;
				background: #DDD8CD;
				top: 49%;
				left: calc(100% + 17px);
				transform: translateY(-50%);
				width: 100%;
				max-width: 131px;
				@include mq(xl) {
					max-width: 60px;
					left: calc(100% + 9px);
				}
				@include mq {
					max-width: 22px;
					height: 1px;
					left: calc(100% + 4px);
				}
			}
			&.active {
				background: #4B4F54;
				color: white;
			}
			.txt-en {
				font-family: $font-PhosphateSolid;
				@include font-size(30);
				font-weight: normal;
				margin-right: 17px;
				letter-spacing: 4px;
				@include mq(xl) {
					@include font-size(23);
					margin-right: 5px;
				}
				@include mq {
					@include font-size(12);
					letter-spacing: 1.3px;
				}
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}
	&-form {
	
	}
	&-note {
		@include font-size(24);
		font-weight: 500;
		margin-bottom: 21px;
		letter-spacing: 1.3px;
		@include mq(xl) {
			@include font-size(18);
		}
		@include mq {
			@include font-size(12);
			letter-spacing: 0.7px;
			margin-bottom: -24px;
		}
		img {
			margin: 0 2px 2px 0;
			@include mq {
				width: 10px;
			}
		}
	}
	&-item {
		display: flex;
		margin-bottom: 48px;
		@include font-size(20);
		line-height: 1.8;
		@include mq {
			display: block;
			@include font-size(16);
			font-weight: bold;
			margin-bottom: 29px;
			line-height: 1.5625;
		}
		dt {
			width: 32.8%;
			font-weight: bold;
			padding-top: 20px;
			@include mq {
				width: 100%;
				padding: 0;
				margin-bottom: 9px;
			}
			img {
				margin: 0 0 14px 5px;
				width: 18px;
				@include mq {
					width: 9px;
					margin: 0 0 10px 3px;
				}
			}
		}
		dd {
			width: 67.2%;
			@include mq {
				width: 100%;
			}
			.comment {
				@include font-size(20);
				font-weight: 500;
				color: #939393;
				@include mq {
					@include font-size(12);
					line-height: 1.25;
				}
			}
		}
	}
	&-btn {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		@include mq(sm) {
			flex-wrap: wrap;
			flex-direction: column-reverse;
		}
		li {
			width: 50%;
			max-width: 456px;
			padding: 0 28px;
			@include mq(xl) {
				max-width: 430px;
				padding: 0 15px;
			}
			@include mq(sm) {
				width: 100%;
				padding: 0;
			}
		}
	}
	&-txt {
		margin-bottom: 162px;
		@include mq {
			margin-bottom: 81px;
			.txt-sm {
				line-height: 1.8125;
			}
		}
	}
}

.error{
	display: block;
	color: #e2231b;
	@include font-size(20);
	font-weight: 500;
	@include mq {
		@include font-size(12);
		line-height: 1.25;
	}
}
.contact-item-note{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	.checkbox{
		margin-bottom: 15px;
		align-items: center;
		img{
			position: relative;
			margin: 0 0 7px 5px;
			@include mq(){
				width: 9px;
				margin: 0 0 10px 3px;
			}
		}
	}
}