//body{
//	padding-bottom: 100000px;
//}

.sec-company{
	@include mq(lp){
		.contact-des {
			margin-bottom: 130px;
		}
	}
	@include mq(){
		.inner{
			padding: 0 27px;
		}
		.contact-des{
			letter-spacing: 2px;
			margin-bottom: 138px;
		}
	}
}

.anchor-link{
	display: flex;
	justify-content: space-between;
	margin: 0 -15px 164px;
	@include mq(lp){
		margin: 0 -12px 100px;
	}
	@include mq(){
		flex-wrap: wrap;
		margin: 0 -20px 0;
	}
	.item{
		padding: 0 15px;
		@include mq(lp){
			padding: 0 12px;
		}
		@media only screen and (min-width: 1070px) and (max-width: 1440px) {
			min-width: 23%;
			&:nth-child(3){
				max-width: 30%;
			}
		}
		@media only screen and (min-width: 835px) and (max-width: 1069px) {
			min-width: 23%;
			&:nth-child(3){
				max-width: 30%;
			}
		}
		@include mq(){
			width: 50%;
			padding: 0 7px;
			margin-bottom: 25px;
		}
		a{
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;
			align-content: flex-end;
			height: 100%;
			line-height: 1;
			border-bottom: 2px solid #ddd8cd;
			letter-spacing: 3.5px;
			padding: 0 114px 31px 7px;
			position: relative;
			@include mq(lp){
				padding: 0 65px 31px 7px;
			}
			@include mq(ipd){
				padding: 0 25px 31px 7px;
			}
			@include mq(){
				padding: 0 0 15px;
				border-bottom: 1px solid #ddd8cd;
			}
			&:after{
				content: "";
				width: 40px;
				height: 63px;
				background: url("../img/common/ico-arr-dow.png") no-repeat;
				background-size: 100%;
				position: absolute;
				bottom: 23px;
				right: 10px;
				@include mq(lp){
					right: 0;
					width: 30px;
					height: 48px;
				}
				@include mq(){
					width: 20px;
					height: 32px;
					bottom: 15px;
					right: 0;
				}
			}
			span{
				display: block;
				width: 100%;
				&.en{
					@include font-size(24);
					margin-bottom: 7px;
					@include mq(lp){
						@include font-size(20);
						letter-spacing: 2.5px;
					}
					@include mq(ipd){
						@include font-size(16);
						line-height: 1.0625;
						margin-bottom: 5px;
					}
					@include mq(sm){
						padding: 0 20px 0 0;
					}
				}
				&.jp{
					@include font-size(20);
					font-weight: 500;
					@include mq(lp){
						@include font-size(16);
					}
					@include mq(ipd){
						@include font-size(13);
						letter-spacing: 2px;
					}
				}
				.txt{
					@media screen and (max-width: 500px) {
						display: none;
					}
				}
				.txt-img{
					display: none;
					@media screen and (max-width: 500px) {
						display: block;
						img{
							width: 86.5px;
						}
					}
				}
			}
		}
	}
}


.about{
	&-ttl {
		margin-bottom: 9px;
		padding: 0;
		@include mq {
			@include font-size(60);
		}
		@include mq(xs){
			@include font-size(40);
		}
	}
	&-show{
		.items{
			margin-bottom: 365px;
			@include mq(){
				margin-bottom: 97px;
			}
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	&-block{
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		align-items: flex-start;
		position: relative;
		margin-bottom: 265px;
		padding-top: 100px;
		@include mq(ipd){
			padding-top: 0;
			margin-bottom: 100px;
		}

		&:last-child{
			margin-bottom: 0;
		}
		&#item{

			@include mq(md-min){
				&-01{

				}
				&-02{
					margin-bottom: 303px;
				}
				&-03{
					margin-bottom: 197px;
				}
			}
		}
		&__ttl{
			width: 715px;
			@include mq(lp){
				width: 400px;
			}
			@include mq(ipd){
				width: 100%;
			}

			@include mq(ipd-min){
				&.none{
					opacity: 0;
				}
				&.active{
					position: fixed;
					top: 115px;
					left: 0;
					@include smooth-transition;
					&.active2{
						opacity: 0;
						visibility: hidden;
					}
					.ttl-comp04{
						&:before{
							top: -10px;
						}
					}
				}
			}
			@include mq(ipd){
				position: static !important;
				& + div{
					display: none !important;
				}
			}
		}
		&__content{
			width: calc(100% - 715px);
			@include mq(lp){
				width: calc(100% - 400px);
			}
			@include mq(ipd){
				width: 100%;
				padding: 0 0 0 17px;
			}
			.inner-small{
				max-width: 962px;
				@include mq(){
					padding: 0 17px 0 0;
				}
			}
		}
	}
	&-heading{
		&__img{
			border-radius: 40px 0 0 40px;
			overflow: hidden;
			position: relative;
			margin-bottom: 42px;
			@include mq(){
				border-radius: 10px 0 0 10px;
				margin-bottom: 7px;
			}
			img{
				width: 100%;
			}
		}
		&__ttl{
			display: block;
			color: #e2231b;
			//@include font-size(84);
			//letter-spacing: 8.8px;
			margin-bottom: 40px;
			font-size: vw(84);
			letter-spacing: vw(8.8);
			white-space: nowrap;
			@include mq(ipd){
				@include font-size(60);
			}
			@include mq(){
				letter-spacing: 3.3px;
				margin: 0 -15px 3px 0;
			}
			@include mq(sm){
				@include font-size(30);
			}
			@include mq(xs){
				@include font-size(25);
			}
		}
		&__note{
			padding: 0 70px 0 0;
			@include mq(){
				padding: 0 20px;
				text-align: justify;
			}
			&01{
				display: flex;
				flex-wrap: wrap;
				@include font-size(15);
				line-height: 1.45;
				font-weight: 500;
				position: relative;
				padding: 0 0 82px 0;
				margin-bottom: 44px;
				@include mq(){
					@include font-size(12);
					line-height: 1.58333;
					padding: 0 0 33px 0;
					margin-bottom: 26px;
				}
				&:after{
					content: "";
					width: 266px;
					height: 4px;
					background: #e2231b;
					position: absolute;
					bottom: 40px;
					left: 0;
					@include mq(){
						width: 135px;
						bottom: 0;
					}
				}
				&-ttl{
					display: block;
					width: 280px;
					@include font-size(20);
					font-weight: 500;
					letter-spacing: 4.4px;
					line-height: 1.5;
					@include mq(){
						width: 100%;
						@include font-size(16);
						line-height: 1.6875;
						letter-spacing: 2.2px;
						margin-bottom: 11px;
					}
					span{
						display: block;
						font-weight: bold;
						@include font-size(25);
						line-height: 1.28;
					}
				}
				p{
					width: calc(100% - 280px);
					padding: 0 0 0 33px;
					letter-spacing: 1.4px;
					@include mq(){
						width: 100%;
						padding: 0;
						line-height: 1.5833333;
						letter-spacing: 0.7px;
					}
				}
			}
			&02{
				line-height: 1.9375;
				@include font-size(20);
				font-weight: 500;
				margin-bottom: 90px;
				@include mq(ipd){
					@include font-size(16);
					line-height: 1.875;
					letter-spacing: 1px;
					margin-bottom: 38px;
					text-align: justify;
				}
				&-ttl{
					display: block;
					@include font-size(30);
					font-weight: bold;
					line-height: 1.4;
					letter-spacing: 2px;
					margin-bottom: 43px;
					@include mq(ipd){
						@include font-size(25);
						line-height: 1.48;
						letter-spacing: 1px;
						padding: 0 20px 0 0;
						margin-bottom: 22px;
					}
					@include mq(xs){
						@include font-size(19);
					}
				}
				p{
					margin-bottom: 40px;
					@include mq(){
						margin-bottom: 30px;
					}
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
			&03{
				//background: #4b4f54;
				//@include font-size(52);
				//color: white;
				//padding: 38px 83px 38px 137px;
				//letter-spacing: 4px;
				//@include mq(){
				//	@include font-size(18.155);
				//	line-height: 1.3439823740;
				//	padding: 16px 37px 16px 51px;
				//	letter-spacing: 1px;
				//	max-width: 340px;
				//}
				p{
					position: relative;
					&:before,&:after{
						content: "";
						width: 45px;
						height: 33px;
						position: absolute;
						top: 14px;
						@include mq(){
							width: 16px;
							height: 16px;
							top: 4px;
						}
					}
					&:before{
						background: url("../img/company/bf.png")no-repeat;
						background-size: 100%;
						left: -71px;
						@include mq(){
							left: -25px;
						}
					}
					&:after{
						background: url("../img/company/af.png")no-repeat;
						background-size: 100%;
						right: -25px;
					}
				}
				small{
					display: block;
					@include font-size(40);
					text-align: right;
					letter-spacing: 0;
					margin: -36px -5px 0 0;
					@include mq(){
						@include font-size(13.965);
						margin: -11px -10px 0 0;
					}
				}
			}
		}
	}
}


.executives{
	&-list{
		padding: 17px 0 0 0;
		@include mq(){
			padding: 0 0 0 2px;
		}
		.item{
			margin-bottom: 0;
			&:last-child{
				margin-bottom: 0;
			}
			@include mq{
				display: flex;
				flex-wrap: wrap;
				align-items: flex-start;
				justify-content: center;
			}
		}
	}
}

.profile{
	&-block{
		padding-top: 5px;
		@include mq(){
			padding: 0 15px;
		}
	}
	&-list{
		width: 100%;
		tr{
			border-bottom: 1px solid #ddd8cd;
			&:nth-child(1){
				th,td{
					padding-top: 0;
				}
			}
			th,td{
				@include font-size(20);
				line-height: 1.5333333;
				vertical-align: top;
				padding: 26px 0;
				@include mq(ipd){
					@include font-size(13);
					line-height: 2.0769230;
					padding: 24px 0;
				}
			}

			th{
				font-weight: bold;
				color: #e2231b;
				letter-spacing: 4px;
				width: 248px;
				@include mq(ipd){
					width: 120px;
					padding-left: 24px;
					letter-spacing: 2px;
				}
				@include mq(){
					padding-left: 7px;
					width: 105px;
				}
			}
			td{
				font-weight: 500;
				table{
					tr{
						border-bottom: none;
						td,th{
							padding: 0;
						}
						td{
							&:nth-child(2){
								padding-left: 30px;
								@include mq(){
									padding-left: 22px;
								}
							}
						}
					}
					&.table-02{
						@include mq(){
							tr{
								td{
									display: block;
									width: 100%;
									padding: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}

.box-right{
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	padding: 47px 27px 0 0;
	margin-bottom: 24px;
	@include mq(){
		padding: 0 0 0;
	}
}
.show-map{
	@include font-size(20);
	line-height: 1.533333;
	font-weight: 500;
	@include mq(){
		@include font-size(16);
		padding: 0 20px;
		line-height: 1.4375;
	}
}
#googleMap{
	width: 100% !important;
	height: 615px !important;
	margin-bottom: 28px;
	@include mq(){
		height: 200px !important;
		margin-bottom: 14px;
	}
}

.sec-company {
	padding: 126px 0 168px 0;
	@include mq {
		padding: 143px 0 32px 0;
	}
}
.company-heading{
	max-width: 1280px;
	margin: 0 auto;
}