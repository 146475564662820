@charset "UTF-8";
/* ====================================================
reset style
==================================================== */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
html {
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 10px;
  /* Base font size, change to 14px or 16px if needed */
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html.no-scroll {
  overflow: hidden;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

address, caption, cite, code, dfn, var {
  font-style: normal;
  font-weight: normal;
}

li {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: '';
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

/*to enable resizing for IE*/
input, textarea, select {
  *font-size: 100%;
}

/*because legend doesn't inherit in IE */
legend {
  color: #000;
}

del, ins {
  text-decoration: none;
}

main {
  display: block;
}

section {
  position: relative;
}

/* ====================================================
Font
==================================================== */
@font-face {
  font-family: 'Phosphate';
  src: url("../fonts/PhosphatePro-Solid.otf");
  src: url("../fonts/PhosphatePro-Solid.eot?#iefix") format("embedded-opentype"), url("../fonts/PhosphatePro-Solid.woff2") format("woff2"), url("../fonts/PhosphatePro-Solid.woff") format("woff"), url("../fonts/PhosphatePro-Solid.otf") format("truetype"), url("../fonts/PhosphatePro-Solid.svg#PhosphatePro-Solid") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* ====================================================
Media Quary
==================================================== */
@media screen and (min-width: 835px) {
  .mbpc-1 {
    margin-bottom: 1px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-1 {
    margin-bottom: 1px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-2 {
    margin-bottom: 2px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-2 {
    margin-bottom: 2px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-3 {
    margin-bottom: 3px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-3 {
    margin-bottom: 3px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-4 {
    margin-bottom: 4px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-4 {
    margin-bottom: 4px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-5 {
    margin-bottom: 5px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-5 {
    margin-bottom: 5px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-6 {
    margin-bottom: 6px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-6 {
    margin-bottom: 6px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-7 {
    margin-bottom: 7px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-7 {
    margin-bottom: 7px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-8 {
    margin-bottom: 8px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-8 {
    margin-bottom: 8px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-9 {
    margin-bottom: 9px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-9 {
    margin-bottom: 9px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-10 {
    margin-bottom: 10px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-10 {
    margin-bottom: 10px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-11 {
    margin-bottom: 11px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-11 {
    margin-bottom: 11px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-12 {
    margin-bottom: 12px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-12 {
    margin-bottom: 12px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-13 {
    margin-bottom: 13px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-13 {
    margin-bottom: 13px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-14 {
    margin-bottom: 14px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-14 {
    margin-bottom: 14px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-15 {
    margin-bottom: 15px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-15 {
    margin-bottom: 15px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-16 {
    margin-bottom: 16px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-16 {
    margin-bottom: 16px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-17 {
    margin-bottom: 17px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-17 {
    margin-bottom: 17px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-18 {
    margin-bottom: 18px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-18 {
    margin-bottom: 18px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-19 {
    margin-bottom: 19px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-19 {
    margin-bottom: 19px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-20 {
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-20 {
    margin-bottom: 20px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-21 {
    margin-bottom: 21px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-21 {
    margin-bottom: 21px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-22 {
    margin-bottom: 22px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-22 {
    margin-bottom: 22px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-23 {
    margin-bottom: 23px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-23 {
    margin-bottom: 23px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-24 {
    margin-bottom: 24px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-24 {
    margin-bottom: 24px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-25 {
    margin-bottom: 25px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-25 {
    margin-bottom: 25px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-26 {
    margin-bottom: 26px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-26 {
    margin-bottom: 26px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-27 {
    margin-bottom: 27px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-27 {
    margin-bottom: 27px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-28 {
    margin-bottom: 28px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-28 {
    margin-bottom: 28px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-29 {
    margin-bottom: 29px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-29 {
    margin-bottom: 29px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-30 {
    margin-bottom: 30px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-30 {
    margin-bottom: 30px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-31 {
    margin-bottom: 31px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-31 {
    margin-bottom: 31px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-32 {
    margin-bottom: 32px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-32 {
    margin-bottom: 32px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-33 {
    margin-bottom: 33px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-33 {
    margin-bottom: 33px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-34 {
    margin-bottom: 34px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-34 {
    margin-bottom: 34px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-35 {
    margin-bottom: 35px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-35 {
    margin-bottom: 35px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-36 {
    margin-bottom: 36px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-36 {
    margin-bottom: 36px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-37 {
    margin-bottom: 37px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-37 {
    margin-bottom: 37px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-38 {
    margin-bottom: 38px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-38 {
    margin-bottom: 38px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-39 {
    margin-bottom: 39px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-39 {
    margin-bottom: 39px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-40 {
    margin-bottom: 40px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-40 {
    margin-bottom: 40px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-41 {
    margin-bottom: 41px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-41 {
    margin-bottom: 41px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-42 {
    margin-bottom: 42px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-42 {
    margin-bottom: 42px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-43 {
    margin-bottom: 43px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-43 {
    margin-bottom: 43px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-44 {
    margin-bottom: 44px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-44 {
    margin-bottom: 44px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-45 {
    margin-bottom: 45px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-45 {
    margin-bottom: 45px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-46 {
    margin-bottom: 46px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-46 {
    margin-bottom: 46px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-47 {
    margin-bottom: 47px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-47 {
    margin-bottom: 47px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-48 {
    margin-bottom: 48px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-48 {
    margin-bottom: 48px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-49 {
    margin-bottom: 49px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-49 {
    margin-bottom: 49px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-50 {
    margin-bottom: 50px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-50 {
    margin-bottom: 50px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-51 {
    margin-bottom: 51px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-51 {
    margin-bottom: 51px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-52 {
    margin-bottom: 52px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-52 {
    margin-bottom: 52px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-53 {
    margin-bottom: 53px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-53 {
    margin-bottom: 53px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-54 {
    margin-bottom: 54px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-54 {
    margin-bottom: 54px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-55 {
    margin-bottom: 55px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-55 {
    margin-bottom: 55px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-56 {
    margin-bottom: 56px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-56 {
    margin-bottom: 56px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-57 {
    margin-bottom: 57px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-57 {
    margin-bottom: 57px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-58 {
    margin-bottom: 58px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-58 {
    margin-bottom: 58px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-59 {
    margin-bottom: 59px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-59 {
    margin-bottom: 59px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-60 {
    margin-bottom: 60px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-60 {
    margin-bottom: 60px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-61 {
    margin-bottom: 61px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-61 {
    margin-bottom: 61px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-62 {
    margin-bottom: 62px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-62 {
    margin-bottom: 62px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-63 {
    margin-bottom: 63px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-63 {
    margin-bottom: 63px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-64 {
    margin-bottom: 64px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-64 {
    margin-bottom: 64px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-65 {
    margin-bottom: 65px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-65 {
    margin-bottom: 65px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-66 {
    margin-bottom: 66px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-66 {
    margin-bottom: 66px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-67 {
    margin-bottom: 67px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-67 {
    margin-bottom: 67px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-68 {
    margin-bottom: 68px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-68 {
    margin-bottom: 68px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-69 {
    margin-bottom: 69px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-69 {
    margin-bottom: 69px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-70 {
    margin-bottom: 70px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-70 {
    margin-bottom: 70px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-71 {
    margin-bottom: 71px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-71 {
    margin-bottom: 71px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-72 {
    margin-bottom: 72px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-72 {
    margin-bottom: 72px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-73 {
    margin-bottom: 73px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-73 {
    margin-bottom: 73px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-74 {
    margin-bottom: 74px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-74 {
    margin-bottom: 74px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-75 {
    margin-bottom: 75px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-75 {
    margin-bottom: 75px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-76 {
    margin-bottom: 76px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-76 {
    margin-bottom: 76px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-77 {
    margin-bottom: 77px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-77 {
    margin-bottom: 77px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-78 {
    margin-bottom: 78px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-78 {
    margin-bottom: 78px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-79 {
    margin-bottom: 79px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-79 {
    margin-bottom: 79px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-80 {
    margin-bottom: 80px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-80 {
    margin-bottom: 80px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-81 {
    margin-bottom: 81px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-81 {
    margin-bottom: 81px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-82 {
    margin-bottom: 82px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-82 {
    margin-bottom: 82px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-83 {
    margin-bottom: 83px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-83 {
    margin-bottom: 83px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-84 {
    margin-bottom: 84px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-84 {
    margin-bottom: 84px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-85 {
    margin-bottom: 85px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-85 {
    margin-bottom: 85px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-86 {
    margin-bottom: 86px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-86 {
    margin-bottom: 86px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-87 {
    margin-bottom: 87px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-87 {
    margin-bottom: 87px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-88 {
    margin-bottom: 88px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-88 {
    margin-bottom: 88px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-89 {
    margin-bottom: 89px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-89 {
    margin-bottom: 89px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-90 {
    margin-bottom: 90px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-90 {
    margin-bottom: 90px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-91 {
    margin-bottom: 91px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-91 {
    margin-bottom: 91px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-92 {
    margin-bottom: 92px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-92 {
    margin-bottom: 92px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-93 {
    margin-bottom: 93px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-93 {
    margin-bottom: 93px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-94 {
    margin-bottom: 94px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-94 {
    margin-bottom: 94px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-95 {
    margin-bottom: 95px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-95 {
    margin-bottom: 95px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-96 {
    margin-bottom: 96px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-96 {
    margin-bottom: 96px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-97 {
    margin-bottom: 97px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-97 {
    margin-bottom: 97px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-98 {
    margin-bottom: 98px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-98 {
    margin-bottom: 98px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-99 {
    margin-bottom: 99px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-99 {
    margin-bottom: 99px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-100 {
    margin-bottom: 100px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-100 {
    margin-bottom: 100px !important;
  }
}

.inner, .inner-1280 {
  display: block;
  width: 100%;
  max-width: 1514px;
  padding: 0 37px;
  margin: 0 auto;
}

.inner-1280 {
  max-width: 1354px;
}

.clearfix {
  *zoom: 1;
}

.clearfix:before {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

a {
  color: inherit;
  text-decoration: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

a:hover {
  opacity: 0.7;
}

@media screen and (min-width: 835px) {
  a[href^="tel"] {
    pointer-events: none;
  }
}

@media screen and (max-width: 834px) {
  .pc {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .pc-ipd {
    display: none !important;
  }
}

@media screen and (max-width: 990px) {
  .pc-lg {
    display: none !important;
  }
}

@media screen and (max-width: 640px) {
  .pc-sm {
    display: none !important;
  }
}

@media screen and (max-width: 374px) {
  .pc-xs {
    display: none !important;
  }
}

@media screen and (min-width: 835px) {
  .sp {
    display: none !important;
  }
}

@media screen and (min-width: 1025px) {
  .sp-ipd {
    display: none !important;
  }
}

@media screen and (min-width: 991px) {
  .sp-lg {
    display: none !important;
  }
}

@media screen and (min-width: 641px) {
  .sp-sm {
    display: none !important;
  }
}

.ttl-comp01 {
  font-family: "Phosphate", sans-serif;
  color: #E2231B;
  font-size: 17rem;
  line-height: 1;
  margin-bottom: 115px;
  letter-spacing: 21px;
  padding-left: 30px;
  text-align: center;
  text-transform: uppercase;
}

@media screen and (max-width: 1440px) {
  .ttl-comp01 {
    font-size: 12rem;
    padding-left: 10px;
    letter-spacing: 7px;
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 1024px) {
  .ttl-comp01 {
    font-size: 9rem;
  }
}

@media screen and (max-width: 834px) {
  .ttl-comp01 {
    font-size: 5.5rem;
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 640px) {
  .ttl-comp01 {
    font-size: 14.66667vw;
  }
}

@media screen and (max-width: 374px) {
  .ttl-comp01 {
    font-size: 4rem;
    margin-bottom: 24px;
  }
}

.ttl-comp03 {
  font-size: 4rem;
  font-weight: bold;
  line-height: 1.6;
  margin: 55px 0 27px;
}

@media screen and (max-width: 1440px) {
  .ttl-comp03 {
    font-size: 2.9rem;
    margin: 30px 0 15px;
  }
}

@media screen and (max-width: 834px) {
  .ttl-comp03 {
    font-size: 1.8rem;
    margin: 18px 0 5px;
  }
}

@media screen and (max-width: 374px) {
  .ttl-comp03 {
    font-size: 1.6rem;
  }
}

.ttl-comp04 {
  display: block;
  position: relative;
  line-height: 1;
  padding: 0 0 0 150px;
}

@media screen and (max-width: 1440px) {
  .ttl-comp04 {
    padding: 0 0 0 80px;
  }
}

@media screen and (max-width: 1024px) {
  .ttl-comp04 {
    padding: 0 0 0 28px;
    margin-bottom: 50px;
  }
}

.ttl-comp04:before {
  content: "";
  width: 10px;
  background: #e2231b;
  position: absolute;
  top: 5px;
  left: 0;
  bottom: -10px;
}

@media screen and (max-width: 834px) {
  .ttl-comp04:before {
    top: 0;
    width: 5px;
    bottom: 0;
  }
}

.ttl-comp04 span {
  display: block;
}

.ttl-comp04 span.en {
  font-size: 7.6rem;
  color: #4b4f54;
  letter-spacing: 9.5px;
  margin-bottom: 21px;
}

@media screen and (max-width: 1440px) {
  .ttl-comp04 span.en {
    font-size: 5rem;
    letter-spacing: 5px;
  }
}

@media screen and (max-width: 1024px) {
  .ttl-comp04 span.en {
    font-size: 3.8rem;
    line-height: 1.07;
    letter-spacing: 4.5px;
    margin-bottom: 2px;
  }
}

@media screen and (max-width: 834px) {
  .ttl-comp04 span.en .txt {
    display: none;
  }
}

.ttl-comp04 span.en .txt-img {
  display: none;
}

@media screen and (max-width: 834px) {
  .ttl-comp04 span.en .txt-img {
    display: block;
  }
  .ttl-comp04 span.en .txt-img img {
    width: 198.5px;
  }
}

.ttl-comp04 span.jp {
  font-size: 2rem;
  letter-spacing: 3px;
  font-weight: 500;
}

@media screen and (max-width: 1024px) {
  .ttl-comp04 span.jp {
    font-size: 1.4rem;
    line-height: 1.928571;
    letter-spacing: 2px;
  }
}

.txt-basic {
  line-height: 1.8125;
  font-size: 3.2rem;
  font-weight: 500;
}

@media screen and (max-width: 1440px) {
  .txt-basic {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 834px) {
  .txt-basic {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 374px) {
  .txt-basic {
    font-size: 1.4rem;
  }
}

.txt-basic .txt-sm {
  font-size: 3rem;
  line-height: 1.8;
  display: block;
}

@media screen and (max-width: 1440px) {
  .txt-basic .txt-sm {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 834px) {
  .txt-basic .txt-sm {
    font-size: 1.6rem;
    line-height: 1.6875;
  }
}

@media screen and (max-width: 374px) {
  .txt-basic .txt-sm {
    font-size: 1.4rem;
  }
}

.txt-basic strong {
  font-weight: bold;
}

.txt-center {
  text-align: center;
}

.txt-right {
  text-align: right;
}

.txt-left {
  text-align: left;
}

.btn-comp01 {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 766px;
  padding: 0 42px;
  margin: 0 auto;
  cursor: pointer;
  font-size: 3rem;
  font-weight: bold;
  border: none;
  background: white;
  color: #4B4F54;
}

@media screen and (max-width: 1440px) {
  .btn-comp01 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 834px) {
  .btn-comp01 {
    font-size: 1.6rem;
    max-width: 299px;
    padding: 0;
  }
}

.btn-comp01:hover {
  opacity: 1;
}

@media screen and (min-width: 835px) {
  .btn-comp01:hover {
    max-width: 682px;
    padding: 0;
  }
  .btn-comp01:hover span {
    border: 4px solid #E2231B;
    background: #E2231B;
    color: white;
  }
  .btn-comp01:hover:after {
    opacity: 0;
    pointer-events: none;
  }
}

.btn-comp01 span {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid #4B4F54;
  background: white;
  border-radius: 100px;
  padding: 15px 15px 15px;
  position: relative;
  z-index: 1;
  width: 100%;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  height: 121px;
}

@media screen and (max-width: 834px) {
  .btn-comp01 span {
    border-width: 2px;
    height: 75px;
  }
}

.btn-comp01:after {
  position: absolute;
  content: '';
  width: 95px;
  height: 12px;
  top: 47%;
  right: 0;
  background: url("../img/common/ico-arr-btn.png") no-repeat;
  background-size: 100% auto;
  z-index: 2;
  transform: translateY(-50%);
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 834px) {
  .btn-comp01:after {
    width: 47px;
    height: 6px;
    top: 48%;
    right: -18px;
  }
}

.btn-comp02 {
  display: flex;
  font-family: "Phosphate", sans-serif;
  padding-right: 52px;
  position: relative;
  letter-spacing: 4px;
  width: 100%;
  max-width: 450px;
}

@media screen and (max-width: 834px) {
  .btn-comp02 {
    padding-right: 0;
    letter-spacing: 2.2px;
    max-width: 250px;
  }
}

.btn-comp02:after {
  position: absolute;
  content: '';
  width: 95px;
  height: 12px;
  top: 46%;
  right: 10px;
  transform: translateY(-50%);
  background: url("../img/common/ico-arr-red-02.png") no-repeat;
  background-size: 100% auto;
}

@media screen and (max-width: 834px) {
  .btn-comp02:after {
    width: 47px;
    height: 10px;
    top: 49%;
    right: 6px;
  }
}

.btn-comp02 span {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #E2231B;
  font-size: 3rem;
  width: 100%;
  max-width: 398px;
  height: 124px;
  border-radius: 100px;
  padding: 0 8px 2px 0;
}

@media screen and (max-width: 1200px) {
  .btn-comp02 span {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 834px) {
  .btn-comp02 span {
    font-size: 1.6rem;
    height: 60px;
  }
}

.btn-comp02 span:after {
  position: absolute;
  content: '';
  border: 4px solid #E2231B;
  width: 32px;
  height: 32px;
  right: -52px;
  top: 47%;
  transform: translateY(-50%);
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 834px) {
  .btn-comp02 span:after {
    width: 16px;
    height: 16px;
    border-width: 2px;
    right: 0;
  }
}

.btn-comp02:hover {
  opacity: 1;
}

@media screen and (min-width: 835px) {
  .btn-comp02:hover span:after {
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    right: 0;
    top: 50%;
    border-radius: 100px;
  }
}

.btn-comp02__r {
  margin-left: auto;
}

.btn-comp03 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border: 3px solid #c4bba8;
  font-weight: 900;
  font-size: 2.4rem;
  line-height: 1;
  color: #ab9977;
  width: 320px;
  height: 70px;
  border-radius: 52px;
  letter-spacing: 2px;
  position: relative;
}

@media screen and (max-width: 834px) {
  .btn-comp03 {
    font-size: 1.6rem;
    max-width: 180px;
    height: 50px;
    letter-spacing: 1px;
    border: 2px solid #c4bba8;
  }
}

.btn-comp03:after {
  content: "";
  width: 94px;
  height: 12px;
  background: url("../img/common/ico-arr-btn-02.png") no-repeat;
  background-size: 100%;
  position: absolute;
  right: -33px;
  top: 22px;
}

@media screen and (max-width: 834px) {
  .btn-comp03:after {
    width: 45px;
    height: 8px;
    right: -22px;
    top: 17px;
  }
}

.btn-comp03 span {
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 834px) {
  .btn-comp03 span {
    top: -2px;
  }
}

.btn-comp04 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 394px;
  height: 120px;
  border: 4px solid #4B4F54;
  border-radius: 100px;
  font-size: 3rem;
  font-weight: bold;
  background: white;
  color: #4B4F54;
  font-family: "Noto Sans JP", "ヒラギノ角ゴPro W6", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  position: relative;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  outline: none;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .btn-comp04 {
    height: 100px;
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 834px) {
  .btn-comp04 {
    height: 75px;
    font-size: 1.6rem;
    border-width: 2px;
  }
}

.btn-comp04:before {
  position: absolute;
  content: '';
  width: 95px;
  height: 12px;
  background-image: url("../img/common/ico-arr-btn.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
}

@media screen and (max-width: 834px) {
  .btn-comp04:before {
    width: 48px;
    height: 6px;
    top: 48%;
    right: 2px;
  }
}

.btn-comp04:hover {
  opacity: 1;
}

@media screen and (min-width: 835px) {
  .btn-comp04:hover {
    color: white;
    border-color: #E2231B;
    background: #E2231B;
  }
  .btn-comp04:hover:before {
    display: none;
  }
}

.btn-comp04--gray {
  color: #ADADAD;
  border-color: #ADADAD;
}

.btn-comp04--gray:before {
  background-image: url("../img/common/ico-arr-gray.png");
  background-repeat: no-repeat;
  right: auto;
  left: 0;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 835px) {
  .btn-comp04--gray:hover {
    background: white;
    color: #4B4F54;
    border-color: #4B4F54;
  }
}

button.btn-comp01, input.btn-comp01 {
  padding-top: 0;
  padding-bottom: 0;
  font-family: "Noto Sans JP", "ヒラギノ角ゴPro W6", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
}

.sec-common {
  position: relative;
  z-index: 1;
  padding-bottom: 151px;
}

@media screen and (max-width: 834px) {
  .sec-common {
    padding-bottom: 44px;
  }
}

.sec-common:before {
  position: absolute;
  content: '';
  top: 88px;
  bottom: 0;
  width: 100vw;
  background: rgba(221, 216, 205, 0.25);
  z-index: -1;
  border-radius: 95px;
  right: 20.3125%;
}

@media screen and (max-width: 1440px) {
  .sec-common:before {
    top: 60px;
  }
}

@media screen and (max-width: 834px) {
  .sec-common:before {
    top: 31px;
    right: 37px;
    border-radius: 46px;
  }
}

@media screen and (max-width: 640px) {
  .sec-common:before {
    top: 8.26667vw;
  }
}

@media screen and (max-width: 374px) {
  .sec-common:before {
    top: 22px;
  }
}

.sec-common--r:before {
  right: auto;
  left: 20.3125%;
}

@media screen and (max-width: 834px) {
  .sec-common--r:before {
    left: 37px;
  }
}

@media screen and (max-width: 640px) {
  .common-list {
    padding-right: 11px;
  }
}

.common-list__item {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 85px;
}

@media screen and (max-width: 640px) {
  .common-list__item {
    margin-bottom: -25px;
  }
}

.common-list__item:nth-child(2n+2) {
  flex-direction: row-reverse;
}

@media screen and (max-width: 640px) {
  .common-list__item:nth-child(2n+2) .common-list__img {
    margin-bottom: 27px;
  }
  .common-list__item:nth-child(2n+2) .common-list__img img {
    max-width: 210px;
    margin-left: auto;
  }
}

@media screen and (max-width: 640px) {
  .common-list__item:nth-child(2n+1) {
    flex-direction: column-reverse;
  }
  .common-list__item:nth-child(2n+1) .common-list__img img {
    width: 87%;
  }
  .common-list__item:nth-child(2n+1) .common-list__content {
    margin-bottom: 27px;
  }
}

.common-list__item:last-child {
  margin-bottom: 0;
}

.common-list__img {
  width: 50%;
  overflow: hidden;
  border-radius: 1.04167vw;
}

@media screen and (max-width: 640px) {
  .common-list__img {
    width: 100%;
    border-radius: 0;
  }
}

.common-list__img img {
  display: block;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  width: 100%;
  height: 100%;
}

.common-list__content {
  width: 50%;
  margin: 0 auto;
  max-width: 31.5625vw;
  padding: 0 0.78125vw;
}

@media screen and (min-width: 835px) {
  .common-list__content {
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 640px) {
  .common-list__content {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
}

.common-list__content p {
  margin-bottom: 2.1875vw;
}

@media screen and (max-width: 834px) {
  .common-list__content p {
    margin-bottom: 12px;
  }
}

.common-list__content p:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 640px) {
  .common-list--r {
    padding-right: 0;
    padding-left: 23px;
  }
}

.common-list--r .common-list__item {
  margin-bottom: 120px;
}

@media screen and (max-width: 1440px) {
  .common-list--r .common-list__item {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 834px) {
  .common-list--r .common-list__item {
    margin-bottom: 46px;
  }
}

.common-list--r .common-list__item:nth-child(2n+2) {
  flex-direction: row;
}

.common-list--r .common-list__item:nth-child(2n+1) {
  flex-direction: row-reverse;
}

.common-list--r .common-list__item:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 640px) {
  .common-list--r .common-list__content {
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 640px) {
  .common-list--r .common-list__img {
    margin: 0 !important;
  }
  .common-list--r .common-list__img img {
    max-width: 100% !important;
  }
}

.checkbox {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  position: relative;
  padding-left: 63px;
  cursor: pointer;
  user-select: none;
  font-size: 3rem;
  font-weight: bold;
  letter-spacing: 2px;
}

@media screen and (max-width: 1200px) {
  .checkbox {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 834px) {
  .checkbox {
    font-size: 1.6rem;
    letter-spacing: 1px;
    padding-left: 32px;
  }
}

.checkbox a {
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: #D2CBBD;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox input:checked ~ .checkmark:after {
  display: block;
}

.checkbox .checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 42px;
  width: 42px;
  border: 4px solid #C4BBA8;
  border-radius: 7px;
}

@media screen and (max-width: 834px) {
  .checkbox .checkmark {
    border-width: 2px;
    width: 21px;
    height: 21px;
  }
}

.checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 11px;
  top: 2px;
  width: 10px;
  height: 20px;
  border: solid #C4BBA8;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media screen and (max-width: 834px) {
  .checkbox .checkmark:after {
    left: 6px;
    top: 1px;
    width: 5px;
    height: 10px;
    border-width: 0 2px 2px 0;
  }
}

.form-control {
  background: #F5F3F0;
  border: none;
  width: 100%;
  height: 100px;
  display: block;
  appearance: none;
  border-radius: 6px;
  margin-bottom: 8px;
  resize: vertical;
  outline: none;
  padding: 15px;
  font-weight: normal;
  color: #4B4F54;
  font-size: 2rem;
}

@media screen and (max-width: 834px) {
  .form-control {
    font-size: 1.6rem;
    height: 60px;
    margin-bottom: 4px;
    padding: 10px;
  }
}

.form-control:last-child {
  margin-bottom: 0;
}

textarea.form-control {
  height: 320px;
}

@media screen and (max-width: 834px) {
  textarea.form-control {
    height: 180px;
  }
}

.btn-comp01[disabled] {
  cursor: not-allowed;
  pointer-events: none;
}

p.form-control {
  padding: 20px 0 20px 42px;
  background: transparent;
  min-height: 100px;
  height: 100%;
}

@media screen and (max-width: 834px) {
  p.form-control {
    padding: 0;
    min-height: auto;
  }
}

.en {
  font-family: "Phosphate", sans-serif;
}

body {
  color: #4B4F54;
  font-family: "Noto Sans JP", "ヒラギノ角ゴPro W6", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;
  overflow: hidden;
}

.header {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  font-family: "Phosphate", sans-serif;
  background: white;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 834px) {
  .header {
    height: 64px;
  }
}

.header:before {
  position: fixed;
  content: '';
  top: 104px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(2, 2, 2, 0.6);
  opacity: 0;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 834px) {
  .header:before {
    opacity: 1;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #E2231B;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(1, 0.01, 0.35, 0.99), -webkit-transform 0.5s cubic-bezier(1, 0.01, 0.35, 0.99);
  }
}

@media screen and (min-width: 835px) {
  .header.show:before {
    opacity: 1;
  }
}

@media screen and (max-width: 834px) {
  .header.active {
    background: transparent;
  }
  .header.active:before {
    transform: translate(0, 0);
  }
  .header.active.fixed {
    box-shadow: none;
  }
  .header.active .header-menu__item > a {
    transform: translateY(0);
    transition-delay: .3s;
    transition-duration: .5s;
  }
  .header.active .header-menu__item .sub-menu__list li a {
    transform: translateY(0);
    transition-delay: .3s;
    transition-duration: .5s;
  }
}

.header.active:before {
  opacity: 1;
  pointer-events: auto;
}

@media screen and (min-width: 835px) {
  .header.active .header-menu__item .header-btn {
    background: #4B4F54;
  }
}

.header__inner {
  padding: 0 0 0 101px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1440px) {
  .header__inner {
    padding: 0 0 0 15px;
  }
}

@media screen and (max-width: 834px) {
  .header__inner {
    padding: 15px 19px;
    align-items: flex-start;
    position: relative;
    z-index: 1;
  }
}

.header__logo {
  margin-top: 4px;
}

.header__logo img {
  width: 192px;
}

.header__logo .on {
  display: none;
}

@media screen and (max-width: 834px) {
  .header__logo {
    width: 100%;
    max-width: 96px;
  }
  .header__logo.show .off {
    display: none;
  }
  .header__logo.show .on {
    display: block;
  }
}

.header-nav {
  margin-left: auto;
}

@media screen and (max-width: 834px) {
  .header-nav {
    position: fixed;
    top: 64px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    height: calc(100% - 64px);
    padding: 65px 40px 0;
    color: white;
    overflow: auto;
    opacity: 0;
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    transform: translateX(-50px);
  }
  .header-nav.on {
    opacity: 1;
    pointer-events: auto;
    transform: translateX(0);
  }
}

@media screen and (max-width: 374px) {
  .header-nav {
    padding: 40px 20px 0;
  }
}

.header-menu {
  display: flex;
  font-size: 3rem;
  font-weight: bold;
  letter-spacing: 4px;
}

@media screen and (max-width: 1440px) {
  .header-menu {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 834px) {
  .header-menu {
    display: block;
    font-size: 4rem;
    letter-spacing: 5.4px;
    margin-bottom: 50px;
  }
}

.header-menu__item {
  margin-left: 92px;
}

@media screen and (max-width: 1440px) {
  .header-menu__item {
    margin-left: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .header-menu__item {
    margin-left: 30px;
  }
}

@media screen and (max-width: 834px) {
  .header-menu__item {
    margin: 0;
    padding: 0 0 30px 0;
    overflow: hidden;
  }
}

.header-menu__item:last-child {
  margin-left: 71px;
}

@media screen and (max-width: 1440px) {
  .header-menu__item:last-child {
    margin-left: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .header-menu__item:last-child {
    margin-left: 20px;
  }
}

@media screen and (max-width: 834px) {
  .header-menu__item:last-child {
    margin-left: 0;
  }
}

.header-menu__item > a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 104px;
  padding-top: 4px;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .header-menu__item > a {
    height: 80px;
  }
}

@media screen and (max-width: 834px) {
  .header-menu__item > a {
    height: auto;
    display: inline-block;
    padding: 0 27px 0 0;
    position: relative;
    transform: translateY(-100%);
    transition-delay: 0;
    transition-duration: .3s;
  }
  .header-menu__item > a .hasSubIcon {
    position: absolute;
    z-index: 1;
    width: 20px;
    height: 20px;
    display: block;
    top: 19px;
    right: 0;
    display: none;
  }
  .header-menu__item > a .hasSubIcon:before, .header-menu__item > a .hasSubIcon:after {
    position: absolute;
    background: white;
    content: '';
  }
  .header-menu__item > a .hasSubIcon:before {
    width: 100%;
    height: 3px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .header-menu__item > a .hasSubIcon:after {
    width: 3px;
    height: 100%;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    -webkit-backface-visibility: hidden;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
  }
  .header-menu__item > a.on .hasSubIcon:after {
    opacity: 0;
  }
}

.header-menu__item > a.header-btn {
  color: white;
  background: #4B4F54;
  width: 260px;
}

@media screen and (max-width: 1024px) {
  .header-menu__item > a.header-btn {
    width: 200px;
  }
}

@media screen and (max-width: 834px) {
  .header-menu__item > a.header-btn {
    background: transparent;
  }
}

@media screen and (min-width: 835px) {
  .header-menu__item > a.header-btn:hover {
    background: #E2231B;
  }
  .header-menu__item > a.header-btn:hover .txt:before {
    display: none;
  }
}

.header-menu__item > a .txt {
  position: relative;
}

.header-menu__item > a .txt:before {
  position: absolute;
  content: '';
  width: 11px;
  height: 11px;
  background: #4B4F54;
  border-radius: 50%;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0;
  transition: 0.3s all;
  pointer-events: none;
}

.header-menu__item > a:hover {
  opacity: 1;
}

@media screen and (min-width: 835px) {
  .header-menu__item > a:hover span:before {
    opacity: 1;
    bottom: -10px;
  }
}

.header-menu__item .sub-menu {
  position: fixed;
  top: 104px;
  left: 0;
  right: 0;
  background: #F8F7F5;
  font-size: 2.4rem;
  padding: 24px 15px 31px 29px;
  opacity: 0;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  z-index: 1;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1200px) {
  .header-menu__item .sub-menu {
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 1024px) {
  .header-menu__item .sub-menu {
    top: 80px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 1024px) and (min-width: 835px) {
  .header-menu__item .sub-menu {
    padding: 20px 10px;
    white-space: nowrap;
    letter-spacing: 2px;
  }
}

@media screen and (max-width: 834px) {
  .header-menu__item .sub-menu {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    opacity: 1;
    background: transparent;
    padding: 0 0 0 0;
    pointer-events: auto;
    transition: none;
    font-size: 1.3rem;
    margin: 18px 0 -11px 0;
  }
}

.header-menu__item .sub-menu__list {
  display: flex;
  width: 100%;
  max-width: 1310px;
  margin: 0 auto;
}

@media screen and (max-width: 1440px) {
  .header-menu__item .sub-menu__list {
    margin: 0 -15px;
  }
}

@media screen and (max-width: 1440px) and (min-width: 835px) {
  .header-menu__item .sub-menu__list {
    justify-content: center;
  }
}

@media screen and (max-width: 834px) {
  .header-menu__item .sub-menu__list {
    margin: 0;
    display: block;
  }
}

.header-menu__item .sub-menu__item {
  padding: 15px;
}

@media screen and (max-width: 834px) {
  .header-menu__item .sub-menu__item {
    padding: 0;
    margin-bottom: 28px;
    overflow: hidden;
  }
  .header-menu__item .sub-menu__item:last-child {
    margin-bottom: 0;
  }
}

.header-menu__item .sub-menu__item a {
  display: block;
  padding-right: 120px;
  position: relative;
  white-space: nowrap;
}

@media screen and (max-width: 1200px) {
  .header-menu__item .sub-menu__item a {
    padding-right: 77px;
  }
}

@media screen and (max-width: 1024px) {
  .header-menu__item .sub-menu__item a {
    padding-right: 60px;
  }
}

@media screen and (max-width: 834px) {
  .header-menu__item .sub-menu__item a {
    color: white;
    padding-right: 0;
    line-height: 1.3;
    transform: translateY(-100%);
    transition-delay: 0;
    transition-duration: .3s;
  }
}

.header-menu__item .sub-menu__item a:hover {
  opacity: 0.5;
}

.header-menu__item .sub-menu__item a .txt-jp {
  font-size: 2rem;
  display: block;
}

@media screen and (max-width: 1440px) {
  .header-menu__item .sub-menu__item a .txt-jp {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 834px) {
  .header-menu__item .sub-menu__item a .txt-jp {
    font-size: 1.4rem;
  }
}

.header-menu__item .sub-menu__item a .ico {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 49px;
}

@media screen and (max-width: 1440px) {
  .header-menu__item .sub-menu__item a .ico {
    width: 40px;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1025px) {
  .header-menu__item .sub-menu__item a .ico {
    right: 30px;
  }
}

@media screen and (max-width: 834px) {
  .header-menu__item .sub-menu__item a .ico {
    display: none;
  }
}

@media screen and (max-width: 834px) {
  .header-menu__item .sub-menu__item a img {
    height: 12px;
    margin-bottom: 4px;
  }
}

.header-menu__item:hover .sub-menu {
  opacity: 1;
  pointer-events: auto;
}

.header-control {
  width: 40px;
  height: 33px;
  position: relative;
  z-index: 1;
  display: none;
  transition: 0.3s all;
  padding-top: 16px;
  font-family: "Phosphate", sans-serif;
  font-size: 1.4rem;
  margin: 2px 0 0 auto;
  text-align: center;
  letter-spacing: 2px;
  display: none;
}

@media screen and (max-width: 834px) {
  .header-control {
    display: block;
  }
}

.header-control:before, .header-control:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 2px;
  background: #4B4F54;
  left: 0;
}

.header-control:before {
  top: 0;
}

.header-control:after {
  top: 9px;
}

.header-control .on {
  display: none;
}

.header-control__close {
  width: 42px;
  color: white;
}

.header-control__close:before, .header-control__close:after {
  background: white;
}

.header-control__close:before {
  top: 6px;
  transform: rotate(18deg);
}

.header-control__close:after {
  top: 6px;
  transform: rotate(-18deg);
}

.header-control__close .close {
  display: none;
}

.header-control__close .on {
  display: block;
}

.main-page {
  margin-top: 104px;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1024px) {
  .main-page {
    margin-top: 80px;
  }
}

@media screen and (max-width: 834px) {
  .main-page {
    margin-top: 64px;
  }
}

.footer {
  background: #4B4F54;
  color: white;
  font-size: 1.5rem;
  padding: 51px 0 32px;
  position: relative;
  text-align: center;
}

@media screen and (max-width: 834px) {
  .footer {
    font-size: 1.2rem;
    padding: 25px 0 14px;
  }
}

.footer .backtop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-family: "Phosphate", sans-serif;
  font-size: 2.4rem;
  color: #E2231B;
  z-index: 9;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .footer .backtop {
    font-size: 1.4rem;
    bottom: 10px;
  }
  .footer .backtop .ico {
    width: 22px;
  }
}

@media screen and (max-width: 834px) {
  .footer .backtop {
    display: none !important;
  }
}

.footer .backtop > * {
  display: block;
}

.footer .backtop .txt {
  text-align: center;
  margin-top: 14px;
}

@media screen and (max-width: 1200px) {
  .footer .backtop .txt {
    margin-top: 5px;
  }
}

.footer .backtop .txt span {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

.footer .backtop.fixed {
  position: absolute;
  bottom: calc(100% + 74px);
  right: 130px;
  letter-spacing: 1.1px;
}

@media screen and (max-width: 1200px) {
  .footer .backtop.fixed {
    bottom: calc(100% + 10px);
    right: 20px;
  }
}

.footer .inner-1280 {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 374px) {
  .footer .inner-1280 {
    padding: 0 15px;
  }
}

.footer-logo {
  width: 192px;
}

@media screen and (max-width: 640px) {
  .footer-logo {
    width: 96px;
    margin: 0 auto 30px;
  }
}

.footer-menu {
  margin-left: auto;
  display: flex;
  margin-top: 16px;
}

@media screen and (max-width: 640px) {
  .footer-menu {
    width: 100%;
    margin: 0 auto;
    justify-content: center;
  }
}

.footer-menu__item {
  margin-left: 51px;
}

@media screen and (max-width: 640px) {
  .footer-menu__item {
    margin: 0 10px;
  }
}

.footer .copyright {
  width: 100%;
  margin-top: 57px;
  font-size: 1rem;
  letter-spacing: 1px;
}

@media screen and (max-width: 834px) {
  .footer .copyright {
    margin-top: 27px;
  }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background: white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.loading:before {
  content: "";
  height: 5px;
  width: 0;
  background: #E2231B;
  position: absolute;
  top: 0;
  left: 0;
  transition: ease 2.5s;
}

.loading-logo {
  position: relative;
  width: 300px;
  height: 89px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
}

.loading-logo .logo-01 {
  position: absolute;
  top: 0;
  left: 0;
}

.loading-logo__fill {
  display: block;
  width: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  transition: ease 1s;
}

.loading-logo__fill img {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  max-width: 300px;
}

@keyframes left-to-right {
  0% {
    left: 0;
    width: 0;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 0;
    left: auto;
    right: 0;
  }
}

@keyframes bar {
  0% {
    left: 0;
  }
  50% {
    width: 100%;
  }
  100% {
    left: 100%;
    opacity: 0;
    visibility: hidden;
  }
}

.is-hello .loading-logo {
  opacity: 1;
  visibility: visible;
  transition: ease 0.5s;
}

.is-hello.active .loading:before {
  width: 150%;
}

.is-hello.active .loading-logo__fill {
  width: 100%;
}

.is-hello.active2 .loading {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: ease 0.5s;
  transition-delay: 1s;
}

.is-hello.active2 .loading:before {
  left: 100%;
  opacity: 0;
}

.is-hello.active2 .loading-logo__fill {
  width: 0;
  left: auto;
  right: 0;
}

.is-hello.active2 .loading-logo__fill img {
  left: auto;
  right: 0;
}

.sec-mv {
  position: relative;
  width: 100%;
  max-width: 1550px;
  margin: 0 auto 144px;
}

@media screen and (max-width: 834px) {
  .sec-mv {
    margin-bottom: 59px;
  }
}

.mv-ttl {
  font-family: "Phosphate", sans-serif;
  font-size: 9vw;
  color: #E2231B;
  text-align: center;
  position: relative;
  z-index: 1;
  line-height: 1;
  letter-spacing: 1.51042vw;
  margin: -5.98958vw -0.98958vw 5.72917vw;
}

@media screen and (max-width: 1200px) {
  .mv-ttl {
    font-size: 15vw;
    letter-spacing: 3vw;
    margin: -8vw -37px 80px;
  }
}

@media screen and (max-width: 640px) {
  .mv-ttl {
    margin: -9vw -37px 35px;
    font-size: 17.2vw;
    letter-spacing: 2.15vw;
  }
}

.mv-txt {
  font-size: 4rem;
  font-weight: bold;
  color: #4B4F54;
  text-align: center;
  position: relative;
  z-index: 1;
  line-height: 2;
  margin-bottom: 33px;
}

@media screen and (max-width: 1200px) {
  .mv-txt {
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 834px) {
  .mv-txt {
    font-size: 2.4rem;
    line-height: 1.35;
    margin-bottom: 20px;
  }
  .mv-txt > * {
    margin-bottom: 25px;
  }
  .mv-txt > *:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 374px) {
  .mv-txt {
    font-size: 2rem;
  }
}

.mv-btn {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.mv-slider .item {
  outline: none !important;
}

.mv-slider .item img {
  display: block;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  width: 100%;
  height: 100%;
}

.mv-slider .item > * {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.mv-slider .slick-list, .mv-slider .slick-track {
  height: 100%;
}

.mv-slider .slick-dots {
  width: 40px;
  bottom: auto;
  right: 123px;
  top: 50%;
  display: flex;
  flex-wrap: wrap;
  transform: translateY(-50%);
}

@media screen and (max-width: 1200px) {
  .mv-slider .slick-dots {
    right: 20px;
  }
}

@media screen and (max-width: 834px) {
  .mv-slider .slick-dots {
    width: 20px;
  }
}

.mv-slider .slick-dots li {
  display: block;
  margin: 2px 0 1px;
}

@media screen and (max-width: 834px) {
  .mv-slider .slick-dots li {
    margin: 0px 0 1px;
  }
}

.mv-slider .slick-dots li button {
  display: block;
  width: 40px;
  height: 40px;
  border: 4px solid transparent;
  border-radius: 50%;
  padding: 0;
}

@media screen and (max-width: 834px) {
  .mv-slider .slick-dots li button {
    width: 20px;
    height: 20px;
  }
}

.mv-slider .slick-dots li button:before {
  border: none;
  width: 12px;
  height: 12px;
  background: #E2231B;
}

@media screen and (max-width: 834px) {
  .mv-slider .slick-dots li button:before {
    width: 6px;
    height: 6px;
  }
}

.mv-slider .slick-dots li.slick-active button {
  border: 2px solid #E2231B;
}

.sec-vision {
  margin-bottom: 184px;
}

@media screen and (max-width: 834px) {
  .sec-vision {
    margin-bottom: 86px;
  }
}

.sec-contact {
  padding: 218px 0 242px;
}

.sec-contact.bg {
  background-image: url("../img/contact/bg.jpg");
  background-repeat: no-repeat;
  background-position: top 121px left -6px;
  background-size: 945px;
}

@media screen and (max-width: 834px) {
  .sec-contact.bg {
    background-image: url("../img/contact/bg-sp.jpg");
    background-position: top 55px left -4px;
    background-size: 257px;
  }
}

@media screen and (max-width: 834px) {
  .sec-contact {
    padding: 92px 0 99px;
  }
}

.contact-ttl {
  margin-bottom: 37px;
}

@media screen and (max-width: 834px) {
  .contact-ttl {
    margin-bottom: 24px;
  }
}

.contact-des {
  margin-bottom: 134px;
  text-align: center;
}

@media screen and (max-width: 1440px) {
  .contact-des {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 834px) {
  .contact-des {
    margin-bottom: 29px;
  }
}

.page-index .inner, .page-index .inner-1280, .page-index .inner-1280 {
  max-width: 1080px;
}

@media screen and (max-width: 1440px) {
  .sec-company .contact-des {
    margin-bottom: 130px;
  }
}

@media screen and (max-width: 834px) {
  .sec-company .inner, .sec-company .inner-1280 {
    padding: 0 27px;
  }
  .sec-company .contact-des {
    letter-spacing: 2px;
    margin-bottom: 138px;
  }
}

.anchor-link {
  display: flex;
  justify-content: space-between;
  margin: 0 -15px 164px;
}

@media screen and (max-width: 1440px) {
  .anchor-link {
    margin: 0 -12px 100px;
  }
}

@media screen and (max-width: 834px) {
  .anchor-link {
    flex-wrap: wrap;
    margin: 0 -20px 0;
  }
}

.anchor-link .item {
  padding: 0 15px;
}

@media screen and (max-width: 1440px) {
  .anchor-link .item {
    padding: 0 12px;
  }
}

@media only screen and (min-width: 1070px) and (max-width: 1440px) {
  .anchor-link .item {
    min-width: 23%;
  }
  .anchor-link .item:nth-child(3) {
    max-width: 30%;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1069px) {
  .anchor-link .item {
    min-width: 23%;
  }
  .anchor-link .item:nth-child(3) {
    max-width: 30%;
  }
}

@media screen and (max-width: 834px) {
  .anchor-link .item {
    width: 50%;
    padding: 0 7px;
    margin-bottom: 25px;
  }
}

.anchor-link .item a {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  align-content: flex-end;
  height: 100%;
  line-height: 1;
  border-bottom: 2px solid #ddd8cd;
  letter-spacing: 3.5px;
  padding: 0 114px 31px 7px;
  position: relative;
}

@media screen and (max-width: 1440px) {
  .anchor-link .item a {
    padding: 0 65px 31px 7px;
  }
}

@media screen and (max-width: 1024px) {
  .anchor-link .item a {
    padding: 0 25px 31px 7px;
  }
}

@media screen and (max-width: 834px) {
  .anchor-link .item a {
    padding: 0 0 15px;
    border-bottom: 1px solid #ddd8cd;
  }
}

.anchor-link .item a:after {
  content: "";
  width: 40px;
  height: 63px;
  background: url("../img/common/ico-arr-dow.png") no-repeat;
  background-size: 100%;
  position: absolute;
  bottom: 23px;
  right: 10px;
}

@media screen and (max-width: 1440px) {
  .anchor-link .item a:after {
    right: 0;
    width: 30px;
    height: 48px;
  }
}

@media screen and (max-width: 834px) {
  .anchor-link .item a:after {
    width: 20px;
    height: 32px;
    bottom: 15px;
    right: 0;
  }
}

.anchor-link .item a span {
  display: block;
  width: 100%;
}

.anchor-link .item a span.en {
  font-size: 2.4rem;
  margin-bottom: 7px;
}

@media screen and (max-width: 1440px) {
  .anchor-link .item a span.en {
    font-size: 2rem;
    letter-spacing: 2.5px;
  }
}

@media screen and (max-width: 1024px) {
  .anchor-link .item a span.en {
    font-size: 1.6rem;
    line-height: 1.0625;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 640px) {
  .anchor-link .item a span.en {
    padding: 0 20px 0 0;
  }
}

.anchor-link .item a span.jp {
  font-size: 2rem;
  font-weight: 500;
}

@media screen and (max-width: 1440px) {
  .anchor-link .item a span.jp {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 1024px) {
  .anchor-link .item a span.jp {
    font-size: 1.3rem;
    letter-spacing: 2px;
  }
}

@media screen and (max-width: 500px) {
  .anchor-link .item a span .txt {
    display: none;
  }
}

.anchor-link .item a span .txt-img {
  display: none;
}

@media screen and (max-width: 500px) {
  .anchor-link .item a span .txt-img {
    display: block;
  }
  .anchor-link .item a span .txt-img img {
    width: 86.5px;
  }
}

.about-ttl {
  margin-bottom: 9px;
  padding: 0;
}

@media screen and (max-width: 834px) {
  .about-ttl {
    font-size: 6rem;
  }
}

@media screen and (max-width: 374px) {
  .about-ttl {
    font-size: 4rem;
  }
}

.about-show .items {
  margin-bottom: 365px;
}

@media screen and (max-width: 834px) {
  .about-show .items {
    margin-bottom: 97px;
  }
}

.about-show .items:last-child {
  margin-bottom: 0;
}

.about-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-start;
  position: relative;
  margin-bottom: 265px;
  padding-top: 100px;
}

@media screen and (max-width: 1024px) {
  .about-block {
    padding-top: 0;
    margin-bottom: 100px;
  }
}

.about-block:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 835px) {
  .about-block#item-02 {
    margin-bottom: 303px;
  }
  .about-block#item-03 {
    margin-bottom: 197px;
  }
}

.about-block__ttl {
  width: 715px;
}

@media screen and (max-width: 1440px) {
  .about-block__ttl {
    width: 400px;
  }
}

@media screen and (max-width: 1024px) {
  .about-block__ttl {
    width: 100%;
  }
}

@media screen and (min-width: 1025px) {
  .about-block__ttl.none {
    opacity: 0;
  }
  .about-block__ttl.active {
    position: fixed;
    top: 115px;
    left: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
  }
  .about-block__ttl.active.active2 {
    opacity: 0;
    visibility: hidden;
  }
  .about-block__ttl.active .ttl-comp04:before {
    top: -10px;
  }
}

@media screen and (max-width: 1024px) {
  .about-block__ttl {
    position: static !important;
  }
  .about-block__ttl + div {
    display: none !important;
  }
}

.about-block__content {
  width: calc(100% - 715px);
}

@media screen and (max-width: 1440px) {
  .about-block__content {
    width: calc(100% - 400px);
  }
}

@media screen and (max-width: 1024px) {
  .about-block__content {
    width: 100%;
    padding: 0 0 0 17px;
  }
}

.about-block__content .inner-small {
  max-width: 962px;
}

@media screen and (max-width: 834px) {
  .about-block__content .inner-small {
    padding: 0 17px 0 0;
  }
}

.about-heading__img {
  border-radius: 40px 0 0 40px;
  overflow: hidden;
  position: relative;
  margin-bottom: 42px;
}

@media screen and (max-width: 834px) {
  .about-heading__img {
    border-radius: 10px 0 0 10px;
    margin-bottom: 7px;
  }
}

.about-heading__img img {
  width: 100%;
}

.about-heading__ttl {
  display: block;
  color: #e2231b;
  margin-bottom: 40px;
  font-size: 4.375vw;
  letter-spacing: 0.45833vw;
  white-space: nowrap;
}

@media screen and (max-width: 1024px) {
  .about-heading__ttl {
    font-size: 6rem;
  }
}

@media screen and (max-width: 834px) {
  .about-heading__ttl {
    letter-spacing: 3.3px;
    margin: 0 -15px 3px 0;
  }
}

@media screen and (max-width: 640px) {
  .about-heading__ttl {
    font-size: 3rem;
  }
}

@media screen and (max-width: 374px) {
  .about-heading__ttl {
    font-size: 2.5rem;
  }
}

.about-heading__note {
  padding: 0 70px 0 0;
}

@media screen and (max-width: 834px) {
  .about-heading__note {
    padding: 0 20px;
    text-align: justify;
  }
}

.about-heading__note01 {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.5rem;
  line-height: 1.45;
  font-weight: 500;
  position: relative;
  padding: 0 0 82px 0;
  margin-bottom: 44px;
}

@media screen and (max-width: 834px) {
  .about-heading__note01 {
    font-size: 1.2rem;
    line-height: 1.58333;
    padding: 0 0 33px 0;
    margin-bottom: 26px;
  }
}

.about-heading__note01:after {
  content: "";
  width: 266px;
  height: 4px;
  background: #e2231b;
  position: absolute;
  bottom: 40px;
  left: 0;
}

@media screen and (max-width: 834px) {
  .about-heading__note01:after {
    width: 135px;
    bottom: 0;
  }
}

.about-heading__note01-ttl {
  display: block;
  width: 280px;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 4.4px;
  line-height: 1.5;
}

@media screen and (max-width: 834px) {
  .about-heading__note01-ttl {
    width: 100%;
    font-size: 1.6rem;
    line-height: 1.6875;
    letter-spacing: 2.2px;
    margin-bottom: 11px;
  }
}

.about-heading__note01-ttl span {
  display: block;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 1.28;
}

.about-heading__note01 p {
  width: calc(100% - 280px);
  padding: 0 0 0 33px;
  letter-spacing: 1.4px;
}

@media screen and (max-width: 834px) {
  .about-heading__note01 p {
    width: 100%;
    padding: 0;
    line-height: 1.5833333;
    letter-spacing: 0.7px;
  }
}

.about-heading__note02 {
  line-height: 1.9375;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 90px;
}

@media screen and (max-width: 1024px) {
  .about-heading__note02 {
    font-size: 1.6rem;
    line-height: 1.875;
    letter-spacing: 1px;
    margin-bottom: 38px;
    text-align: justify;
  }
}

.about-heading__note02-ttl {
  display: block;
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 2px;
  margin-bottom: 43px;
}

@media screen and (max-width: 1024px) {
  .about-heading__note02-ttl {
    font-size: 2.5rem;
    line-height: 1.48;
    letter-spacing: 1px;
    padding: 0 20px 0 0;
    margin-bottom: 22px;
  }
}

@media screen and (max-width: 374px) {
  .about-heading__note02-ttl {
    font-size: 1.9rem;
  }
}

.about-heading__note02 p {
  margin-bottom: 40px;
}

@media screen and (max-width: 834px) {
  .about-heading__note02 p {
    margin-bottom: 30px;
  }
}

.about-heading__note02 p:last-child {
  margin-bottom: 0;
}

.about-heading__note03 p {
  position: relative;
}

.about-heading__note03 p:before, .about-heading__note03 p:after {
  content: "";
  width: 45px;
  height: 33px;
  position: absolute;
  top: 14px;
}

@media screen and (max-width: 834px) {
  .about-heading__note03 p:before, .about-heading__note03 p:after {
    width: 16px;
    height: 16px;
    top: 4px;
  }
}

.about-heading__note03 p:before {
  background: url("../img/company/bf.png") no-repeat;
  background-size: 100%;
  left: -71px;
}

@media screen and (max-width: 834px) {
  .about-heading__note03 p:before {
    left: -25px;
  }
}

.about-heading__note03 p:after {
  background: url("../img/company/af.png") no-repeat;
  background-size: 100%;
  right: -25px;
}

.about-heading__note03 small {
  display: block;
  font-size: 4rem;
  text-align: right;
  letter-spacing: 0;
  margin: -36px -5px 0 0;
}

@media screen and (max-width: 834px) {
  .about-heading__note03 small {
    font-size: 1.3965rem;
    margin: -11px -10px 0 0;
  }
}

.executives-list {
  padding: 17px 0 0 0;
}

@media screen and (max-width: 834px) {
  .executives-list {
    padding: 0 0 0 2px;
  }
}

.executives-list .item {
  margin-bottom: 0;
}

.executives-list .item:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 834px) {
  .executives-list .item {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }
}

.profile-block {
  padding-top: 5px;
}

@media screen and (max-width: 834px) {
  .profile-block {
    padding: 0 15px;
  }
}

.profile-list {
  width: 100%;
}

.profile-list tr {
  border-bottom: 1px solid #ddd8cd;
}

.profile-list tr:nth-child(1) th, .profile-list tr:nth-child(1) td {
  padding-top: 0;
}

.profile-list tr th, .profile-list tr td {
  font-size: 2rem;
  line-height: 1.5333333;
  vertical-align: top;
  padding: 26px 0;
}

@media screen and (max-width: 1024px) {
  .profile-list tr th, .profile-list tr td {
    font-size: 1.3rem;
    line-height: 2.0769230;
    padding: 24px 0;
  }
}

.profile-list tr th {
  font-weight: bold;
  color: #e2231b;
  letter-spacing: 4px;
  width: 248px;
}

@media screen and (max-width: 1024px) {
  .profile-list tr th {
    width: 120px;
    padding-left: 24px;
    letter-spacing: 2px;
  }
}

@media screen and (max-width: 834px) {
  .profile-list tr th {
    padding-left: 7px;
    width: 105px;
  }
}

.profile-list tr td {
  font-weight: 500;
}

.profile-list tr td table tr {
  border-bottom: none;
}

.profile-list tr td table tr td, .profile-list tr td table tr th {
  padding: 0;
}

.profile-list tr td table tr td:nth-child(2) {
  padding-left: 30px;
}

@media screen and (max-width: 834px) {
  .profile-list tr td table tr td:nth-child(2) {
    padding-left: 22px;
  }
}

@media screen and (max-width: 834px) {
  .profile-list tr td table.table-02 tr td {
    display: block;
    width: 100%;
    padding: 0;
  }
}

.box-right {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 47px 27px 0 0;
  margin-bottom: 24px;
}

@media screen and (max-width: 834px) {
  .box-right {
    padding: 0 0 0;
  }
}

.show-map {
  font-size: 2rem;
  line-height: 1.533333;
  font-weight: 500;
}

@media screen and (max-width: 834px) {
  .show-map {
    font-size: 1.6rem;
    padding: 0 20px;
    line-height: 1.4375;
  }
}

#googleMap {
  width: 100% !important;
  height: 615px !important;
  margin-bottom: 28px;
}

@media screen and (max-width: 834px) {
  #googleMap {
    height: 200px !important;
    margin-bottom: 14px;
  }
}

.sec-company {
  padding: 126px 0 168px 0;
}

@media screen and (max-width: 834px) {
  .sec-company {
    padding: 143px 0 32px 0;
  }
}

.company-heading {
  max-width: 1280px;
  margin: 0 auto;
}

.sec-contact-page {
  padding: 127px 0 206px;
  background: url("../img/contact/bg.jpg") no-repeat;
  background-position: top 2% left 0%;
  background-size: 44%;
}

@media screen and (max-width: 834px) {
  .sec-contact-page {
    background: url("../img/contact/bg-sp.jpg") no-repeat;
    background-size: 246px;
    background-position: top 8px left 0px;
    padding: 56px 0 64px;
  }
}

.sec-contact-page .inner-1280 {
  max-width: 1080px;
}

.contact-page__ttl {
  margin-bottom: 8px;
}

@media screen and (max-width: 834px) {
  .contact-page__ttl {
    font-size: 6rem;
  }
  .contact-page__ttl.style {
    font-size: 5.5rem;
    margin: 0 -27px 8px;
  }
}

@media screen and (max-width: 374px) {
  .contact-page__ttl {
    font-size: 4rem;
  }
}

.contact-page__des {
  margin-bottom: 148px;
  letter-spacing: 3.6px;
}

@media screen and (max-width: 834px) {
  .contact-page__des {
    letter-spacing: 1.8px;
    margin-bottom: 52px;
  }
}

.contact-step {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 122px;
  text-align: center;
  font-weight: bold;
  font-size: 2.6rem;
}

@media screen and (max-width: 1200px) {
  .contact-step {
    font-size: 2rem;
  }
}

@media screen and (max-width: 834px) {
  .contact-step {
    font-size: 1.2rem;
    margin: 0 -18px 63px;
    width: calc(100% + 36px);
  }
}

.contact-step__item {
  border: 3px solid #4B4F54;
  border-radius: 10px;
  width: calc(33.33% - 110px);
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media screen and (max-width: 1200px) {
  .contact-step__item {
    width: calc(33.33% - 50px);
    height: 55px;
  }
}

@media screen and (max-width: 834px) {
  .contact-step__item {
    width: calc(33.33% - 19px);
    height: 27px;
    border-width: 1px;
    border-radius: 5px;
  }
}

.contact-step__item:after {
  position: absolute;
  content: '';
  height: 2px;
  background: #DDD8CD;
  top: 49%;
  left: calc(100% + 17px);
  transform: translateY(-50%);
  width: 100%;
  max-width: 131px;
}

@media screen and (max-width: 1200px) {
  .contact-step__item:after {
    max-width: 60px;
    left: calc(100% + 9px);
  }
}

@media screen and (max-width: 834px) {
  .contact-step__item:after {
    max-width: 22px;
    height: 1px;
    left: calc(100% + 4px);
  }
}

.contact-step__item.active {
  background: #4B4F54;
  color: white;
}

.contact-step__item .txt-en {
  font-family: "Phosphate", sans-serif;
  font-size: 3rem;
  font-weight: normal;
  margin-right: 17px;
  letter-spacing: 4px;
}

@media screen and (max-width: 1200px) {
  .contact-step__item .txt-en {
    font-size: 2.3rem;
    margin-right: 5px;
  }
}

@media screen and (max-width: 834px) {
  .contact-step__item .txt-en {
    font-size: 1.2rem;
    letter-spacing: 1.3px;
  }
}

.contact-step__item:last-child:after {
  display: none;
}

.contact-note {
  font-size: 2.4rem;
  font-weight: 500;
  margin-bottom: 21px;
  letter-spacing: 1.3px;
}

@media screen and (max-width: 1200px) {
  .contact-note {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 834px) {
  .contact-note {
    font-size: 1.2rem;
    letter-spacing: 0.7px;
    margin-bottom: -24px;
  }
}

.contact-note img {
  margin: 0 2px 2px 0;
}

@media screen and (max-width: 834px) {
  .contact-note img {
    width: 10px;
  }
}

.contact-item {
  display: flex;
  margin-bottom: 48px;
  font-size: 2rem;
  line-height: 1.8;
}

@media screen and (max-width: 834px) {
  .contact-item {
    display: block;
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 29px;
    line-height: 1.5625;
  }
}

.contact-item dt {
  width: 32.8%;
  font-weight: bold;
  padding-top: 20px;
}

@media screen and (max-width: 834px) {
  .contact-item dt {
    width: 100%;
    padding: 0;
    margin-bottom: 9px;
  }
}

.contact-item dt img {
  margin: 0 0 14px 5px;
  width: 18px;
}

@media screen and (max-width: 834px) {
  .contact-item dt img {
    width: 9px;
    margin: 0 0 10px 3px;
  }
}

.contact-item dd {
  width: 67.2%;
}

@media screen and (max-width: 834px) {
  .contact-item dd {
    width: 100%;
  }
}

.contact-item dd .comment {
  font-size: 2rem;
  font-weight: 500;
  color: #939393;
}

@media screen and (max-width: 834px) {
  .contact-item dd .comment {
    font-size: 1.2rem;
    line-height: 1.25;
  }
}

.contact-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 640px) {
  .contact-btn {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}

.contact-btn li {
  width: 50%;
  max-width: 456px;
  padding: 0 28px;
}

@media screen and (max-width: 1200px) {
  .contact-btn li {
    max-width: 430px;
    padding: 0 15px;
  }
}

@media screen and (max-width: 640px) {
  .contact-btn li {
    width: 100%;
    padding: 0;
  }
}

.contact-txt {
  margin-bottom: 162px;
}

@media screen and (max-width: 834px) {
  .contact-txt {
    margin-bottom: 81px;
  }
  .contact-txt .txt-sm {
    line-height: 1.8125;
  }
}

.error {
  display: block;
  color: #e2231b;
  font-size: 2rem;
  font-weight: 500;
}

@media screen and (max-width: 834px) {
  .error {
    font-size: 1.2rem;
    line-height: 1.25;
  }
}

.contact-item-note {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.contact-item-note .checkbox {
  margin-bottom: 15px;
  align-items: center;
}

.contact-item-note .checkbox img {
  position: relative;
  margin: 0 0 7px 5px;
}

@media screen and (max-width: 834px) {
  .contact-item-note .checkbox img {
    width: 9px;
    margin: 0 0 10px 3px;
  }
}
