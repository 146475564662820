body {
	color: #4B4F54;
	font-family: $font-notoJp;
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	line-height: 1.4;
	overflow: hidden;
}



//header
.header{
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	font-family: $font-PhosphateSolid;
	background: white;
	@include smooth-transition;
	@include mq {
		height: 64px;
	}
	&:before {
		position: fixed;
		content: '';
		top: 104px;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(#020202, 0.6);
		opacity: 0;
		pointer-events: none;
		@include smooth-transition;
		@include mq {
			//display: none;
			opacity: 1;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			background: #E2231B;
			transform: translate(-100%, 0);
			transition: transform .5s cubic-bezier(1,.01,.35,.99),-webkit-transform .5s cubic-bezier(1,.01,.35,.99);
		}
	}
	&.show {
		&:before {
			@include mq(md-min) {
				opacity: 1;
			}
		}
	}
	&.active {
		@include mq {
			//background: #E2231B;
			background: transparent;
			&:before {
				transform: translate(0, 0);
			}
			&.fixed {
				box-shadow: none;
			}
			.header-menu__item {
				> a {
					transform: translateY(0);
					transition-delay: .3s;
					transition-duration: .5s;
				}
				.sub-menu__list{
					li{
						a{
							transform: translateY(0);
							transition-delay: .3s;
							transition-duration: .5s;
						}
					}
				}
			}
		}
		&:before {
			opacity: 1;
			pointer-events: auto;
		}
		@include mq(md-min) {
			.header-menu__item {
				.header-btn {
					background: #4B4F54;
				}
			}
		}
	}
	//&.fixed {
	//	box-shadow: 0 0 10px rgba(0,0,0,0.2);
	//}
	&__inner {
		padding: 0 0 0 101px;
		display: flex;
		align-items: center;
		@include mq(lp) {
			padding: 0 0 0 15px;
		}
		@include mq {
			padding: 15px 19px;
			align-items: flex-start;
			position: relative;
			z-index: 1;
		}
	}
	&__logo {
		margin-top: 4px;
		img{
			width: 192px;
		}
		.on {
			display: none;
		}
		@include mq {
			width: 100%;
			max-width: 96px;
			&.show {
				.off {
					display: none;
				}
				.on {
					display: block;
				}
			}
		}
	}

	&-nav {
		margin-left: auto;
		@include mq {
			position: fixed;
			top: 64px;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 999;
			height: calc(100% - 64px);
			padding: 65px 40px 0;
			color: white;
			overflow: auto;
			opacity: 0;
			pointer-events: none;
			@include smooth-transition;
			//background: #E2231B;
			transform: translateX(-50px);
			&.on {
				opacity: 1;
				pointer-events: auto;
				transform: translateX(0);
			}
		}
		@include mq(xs) {
			padding: 40px 20px 0;
		}
	}
	&-menu {
		display: flex;
		@include font-size(30);
		font-weight: bold;
		letter-spacing: 4px;
		@include mq(lp) {
			@include font-size(24);
		}
		@include mq {
			display: block;
			@include font-size(40);
			letter-spacing: 5.4px;
			margin-bottom: 50px;
		}
		&__item {
			margin-left: 92px;
			@include mq(lp) {
				margin-left: 50px;
			}
			@include mq(ipd) {
				margin-left: 30px;
			}
			@include mq {
				margin: 0;
				padding: 0 0 30px 0;
				overflow: hidden;
			}
			&:last-child {
				margin-left: 71px;
				@include mq(lp) {
					margin-left: 40px;
				}
				@include mq(ipd) {
					margin-left: 20px;
				}
				@include mq {
					margin-left: 0;
				}
			}
			> a {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 104px;
				padding-top: 4px;
				cursor: pointer;
				@include mq(ipd) {
					height: 80px;
				}
				@include mq {
					height: auto;
					display: inline-block;
					padding: 0 27px 0 0;
					position: relative;
					transform: translateY(-100%);
					transition-delay: 0;
					transition-duration: .3s;
					.hasSubIcon {
						position: absolute;
						z-index: 1;
						width: 20px;
						height: 20px;
						display: block;
						top: 19px;
						right: 0;
						display: none;
						&:before, &:after {
							position: absolute;
							background: white;
							content: '';
						}
						&:before {
							width: 100%;
							height: 3px;
							left: 0;
							top: 50%;
							transform: translateY(-50%);
						}
						&:after {
							width: 3px;
							height: 100%;
							left: 50%;
							top: 0;
							transform: translateX(-50%);
							@include smooth-transition;
						}
					}
					&.on {
						.hasSubIcon {
							&:after {
								opacity: 0;
							}
						}
					}
				}
				&.header-btn {
					color: white;
					background: #4B4F54;
					width: 260px;
					@include mq(ipd) {
						width: 200px;
					}
					@include mq {
						background: transparent;
					}
					&:hover {
						@include mq(md-min) {
							background: #E2231B;
							.txt {
								&:before {
									display: none;
								}
							}
						}
					}
				}
				.txt {
					position: relative;
					&:before {
						position: absolute;
						content: '';
						width: 11px;
						height: 11px;
						background: #4B4F54;
						border-radius: 50%;
						bottom: 0;
						left: 50%;
						transform: translate(-50%,0);
						opacity: 0;
						transition: 0.3s all;
						pointer-events: none;
					}
				}
				&:hover {
					opacity: 1;
					@include mq(md-min) {
						span {
							&:before {
								opacity: 1;
								bottom: -10px;
							}
						}
					}
				}
			}

			.sub-menu {
				position: fixed;
				top: 104px;
				left: 0;
				right: 0;
				background: #F8F7F5;
				@include font-size(24);
				padding: 24px 15px 31px 29px;
				opacity: 0;
				pointer-events: none;
				@include smooth-transition;
				z-index: 1;
				display: flex;
				justify-content: center;
				@include mq(xl){
					@include font-size(19);
				}
				@include mq(ipd) {
					top: 80px;
					@include font-size(16);
					@include mq(md-min){
						padding: 20px 10px;
						white-space: nowrap;
						letter-spacing: 2px;
					}
				}
				@include mq {
					position: relative;
					top: auto;
					left: auto;
					right: auto;
					opacity: 1;
					background: transparent;
					padding: 0 0 0 0;
					pointer-events: auto;
					transition: none;
					@include font-size(13);
					margin: 18px 0 -11px 0;
				}
				&__list {
					display: flex;
					width: 100%;
					max-width: 1310px;
					margin: 0 auto;
					@include mq(lp) {
						margin: 0 -15px;
						@include mq(md-min){
							justify-content: center;
						}
					}

					@include mq {
						margin: 0;
						display: block;
					}
				}
				&__item {
					padding: 15px;
					@include mq {
						padding: 0;
						margin-bottom: 28px;
						overflow: hidden;
						&:last-child {
							margin-bottom: 0;
						}
					}
					a {
						display: block;
						padding-right: 120px;
						position: relative;
						white-space: nowrap;
						@media screen and (max-width: 1200px) {
							padding-right: 77px;
						}
						@include mq(ipd){
							padding-right: 60px;
						}
						@include mq {
							color: white;
							padding-right: 0;
							line-height: 1.3;
							transform: translateY(-100%);
							transition-delay: 0;
							transition-duration: .3s;
						}
						&:hover {
							opacity: 0.5;
						}
						.txt-jp {
							@include font-size(20);
							display: block;
							@include mq(lp) {
								@include font-size(14);
							}
							@include mq {
								@include font-size(14);
							}
						}
						.ico {
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							right: 0;
							width: 49px;
							@include mq(lp){
								width: 40px;
								@include mq(ipd-min) {
									right: 30px;
								}
							}
							@include mq {
								display: none;
							}
						}
						img{
							@include mq(){
								height: 12px;
								margin-bottom: 4px;
							}
						}
					}
				}
			}
			&:hover {
				.sub-menu {
					opacity: 1;
					pointer-events: auto;
				}
			}
		}
	}
	&-control {
		width: 40px;
		height: 33px;
		position: relative;
		z-index: 1;
		display: none;
		transition: 0.3s all;
		padding-top: 16px;
		font-family: $font-PhosphateSolid;
		@include font-size(14);
		margin: 2px 0 0 auto;
		text-align: center;
		letter-spacing: 2px;
		display: none;
		@include mq {
			display: block;
		}
		&:before, &:after {
			position: absolute;
			content: '';
			width: 100%;
			height: 2px;
			background: #4B4F54;
			left: 0;
		}
		&:before {
			top: 0;
		}
		&:after {
			top: 9px;
		}
		.on {
			display: none;
		}
		&__close {
			width: 42px;
			color: white;
			&:before, &:after {
				background: white;
			}
			&:before {
				top: 6px;
				transform: rotate(18deg);
			}
			&:after {
				top: 6px;
				transform: rotate(-18deg);
			}
			.close {
				display: none;
			}
			.on {
				display: block;
			}
		}
	}
}

// main-page
.main-page {
	margin-top: 104px;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
	@include mq(ipd) {
		margin-top: 80px;
	}
	@include mq {
		margin-top: 64px;
	}
}

//footer
.footer{
	background: #4B4F54;
	color: white;
	@include font-size(15);
	padding: 51px 0 32px;
	position: relative;
	text-align: center;
	@include mq {
		@include font-size(12);
		padding: 25px 0 14px;
	}
	.backtop {
		position: fixed;
		bottom: 20px;
		right: 20px;
		font-family: $font-PhosphateSolid;
		@include font-size(24);
		color: #E2231B;
		z-index: 9;
		cursor: pointer;
		@include mq(xl) {
			@include font-size(14);
			bottom: 10px;
			.ico {
				width: 22px;
			}
		}
		@include mq {
			display: none !important;
		}
		> * {
			display: block;
		}
		.txt {
			text-align: center;
			margin-top: 14px;
			@include mq(xl) {
				margin-top: 5px;
			}
			span {
				writing-mode: vertical-rl;
				text-orientation: mixed;
			}
		}
		&.fixed {
			position: absolute;
			bottom: calc(100% + 74px);
			right: 130px;
			letter-spacing: 1.1px;
			@include mq(xl) {
				bottom: calc(100% + 10px);
				right: 20px;
			}
		}
	}
	.inner-1280 {
		display: flex;
		flex-wrap: wrap;
		@include mq(xs) {
			padding: 0 15px;
		}
	}
	&-logo {
		width: 192px;
		@include mq(sm) {
			width: 96px;
			margin: 0 auto 30px;
		}
	}
	&-menu {
		margin-left: auto;
		display: flex;
		margin-top: 16px;
		@include mq(sm) {
			width: 100%;
			margin: 0 auto;
			justify-content: center;
		}
		&__item {
			margin-left: 51px;
			@include mq(sm) {
				margin: 0 10px;
			}
		}
	}
	.copyright {
		width: 100%;
		margin-top: 57px;
		@include font-size(10);
		letter-spacing: 1px;
		@include mq {
			margin-top: 27px;
		}
	}
}



.loading{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99999;
	background: white;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	&:before{
		content: "";
		height: 5px;
		width: 0;
		background: #E2231B;
		position: absolute;
		top: 0;
		left: 0;
		transition: ease 2.5s;
	}
	&-logo{
		position: relative;
		width: 300px;
		height: 89px;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		overflow: hidden;
		opacity: 0;
		visibility: hidden;
		.logo{
			&-01{
				position: absolute;
				top: 0;
				left: 0;
			}
		}
		&__fill{
			display: block;
			width: 0;
			overflow: hidden;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			transition: ease 1s;
			img{
				position: absolute;
				top: 0;
				left: 0;
				width: 300px;
				max-width: 300px;
			}
		}
	}
}

@keyframes left-to-right {
	0% {
		left: 0;
		width: 0;
	}
	50% {
		width: 100%;
	}
	100%{
		width: 0;
		left: auto;
		right: 0;
	}
}

@keyframes bar {
	0% {
		left: 0;
	}
	50%{
		width: 100%;
	}
	100%{
		left: 100%;
		opacity: 0;
		visibility: hidden;
	}
}


.is-hello{
	.loading{
		//&:before{
		//	width: 150%;
		//}
		&-logo{
			opacity: 1;
			visibility: visible;
			transition: ease 0.5s;
		}
	}
	&.active{
		.loading{
			&:before{
				width: 150%;
			}

			&-logo{
				&__fill{
					width: 100%;
					//left: auto;
					//right: 0;
					//img{
					//	left: auto;
					//	right: 0;
					//}
				}
			}
		}
	}
	&.active2{
		.loading{
			opacity: 0;
			visibility: hidden;
			z-index: -1;
			transition: ease 0.5s;
			transition-delay: 1s;
			&:before{
				left: 100%;
				opacity: 0;
			}
			&-logo{
				&__fill{
					width: 0;
					left: auto;
					right: 0;
					img{
						left: auto;
						right: 0;
					}
				}
			}
		}
	}
}


