.inner {
	display: block;
	width: 100%;
	max-width: 1514px;
	padding: 0 37px;
	margin: 0 auto;
	&-1280 {
		@extend .inner;
		max-width: 1354px;
	}
}


.clearfix {
	*zoom: 1;
	&:before {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
		content: "";
		display: table;
	}
}

a{
	color: inherit;
	text-decoration: none;
	@include smooth-transition;
	&:hover{
		opacity: 0.7;
	}
	&[href^="tel"] {
		@include mq(md-min){
			pointer-events: none;
		}
	}
}


.pc{
	@include mq(){
		display: none !important;
	}
	&-ipd{
		@include mq(ipd){
			display: none !important;
		}
	}
	&-lg{
		@include mq(lg){
			display: none !important;
		}
	}
	&-sm{
		@include mq(sm){
			display: none !important;
		}
	}
	&-xs{
		@include mq(xs){
			display: none !important;
		}
	}
}
.sp{
	@include mq(md-min){
		display: none !important;
	}
	&-ipd{
		@include mq(ipd-min){
			display: none !important;
		}
	}
	&-lg{
		@include mq(lg-min){
			display: none !important;
		}
	}
	&-sm{
		@include mq(sm-min){
			display: none !important;
		}
	}
}


//ttl
.ttl-comp{
	&01{
		font-family: $font-PhosphateSolid;
		color: #E2231B;
		@include font-size(170);
		line-height: 1;
		margin-bottom: 115px;
		letter-spacing: 21px;
		padding-left: 30px;
		text-align: center;
		text-transform: uppercase;
		@include mq(lp) {
			@include font-size(120);
			padding-left: 10px;
			letter-spacing: 7px;
			margin-bottom: 70px;
		}
		@include mq(ipd) {
			@include font-size(90);
		}
		@include mq {
			@include font-size(55);
			margin-bottom: 24px;
		}
		@include mq(sm){
			font-size: vwsp(55);
		}
		@include mq(xs) {
			@include font-size(40);
			margin-bottom: 24px;
		}
	}
	&02{

	}
	&03{
		@include font-size(40);
		font-weight: bold;
		line-height: 1.6;
		margin: 55px 0 27px;
		@include mq(lp) {
			@include font-size(29);
			margin: 30px 0 15px;
		}
		@include mq {
			@include font-size(18);
			margin: 18px 0 5px;
		}
		@include mq(xs) {
			@include font-size(16);
		}
	}
	&04{
		display: block;
		position: relative;
		line-height: 1;
		padding: 0 0 0 150px;
		@include mq(lp){
			padding: 0 0 0 80px;
		}
		@include mq(ipd){
			padding: 0 0 0 28px;
			margin-bottom: 50px;
		}
		&:before{
			content: "";
			width: 10px;
			background: #e2231b;
			position: absolute;
			top: 5px;
			left: 0;
			bottom: -10px;
			@include mq(){
				top: 0;
				width: 5px;
				bottom: 0;
			}
		}
		span{
			display: block;
			&.en{
				@include font-size(76);
				color: #4b4f54;
				letter-spacing: 9.5px;
				margin-bottom: 21px;
				@include mq(lp){
					@include font-size(50);
					letter-spacing: 5px;
				}
				@include mq(ipd){
					@include font-size(38);
					line-height: 1.07;
					letter-spacing: 4.5px;
					margin-bottom: 2px;
				}
				.txt{
					@include mq(){
						display: none;
					}
				}
				.txt-img{
					display: none;
					@include mq(){
						display: block;
						img{
							width: 198.5px;
						}
					}
				}
			}
			&.jp{
				@include font-size(20);
				letter-spacing: 3px;
				font-weight: 500;
				@include mq(ipd){
					@include font-size(14);
					line-height: 1.928571;
					letter-spacing: 2px;
				}
			}
		}
	}
}

//text
.txt{
	&-basic{
		line-height: 1.8125;
		@include font-size(32);
		font-weight: 500;
		@include mq(lp) {
			@include font-size(24);
		}
		@include mq {
			@include font-size(16);
		}
		@include mq(xs) {
			@include font-size(14);
		}
		.txt-sm {
			@include font-size(30);
			line-height: 1.8;
			display: block;
			@include mq(lp) {
				@include font-size(22);
			}
			@include mq {
				@include font-size(16);
				line-height: 1.6875;
			}
			@include mq(xs) {
				@include font-size(14);
			}
		}
		strong {
			font-weight: bold;
		}
	}
	&-center {
		text-align: center;
	}
	&-right {
		text-align: right;
	}
	&-left {
		text-align: left;
	}
}

//btn
.btn {
	&-comp01 {
		position: relative;
		display: flex;
		width: 100%;
		max-width: 766px;
		padding: 0 42px;
		margin: 0 auto;
		cursor: pointer;
		@include font-size(30);
		font-weight: bold;
		border: none;
		background: white;
		color: #4B4F54;
		@include mq(lp) {
			@include font-size(24);
		}
		@include mq {
			@include font-size(16);
			max-width: 299px;
			padding: 0;
		}
		&:hover {
			opacity: 1;
			@include mq(md-min) {
				max-width: 682px;
				padding: 0;
				span {
					border: 4px solid #E2231B;
					background: #E2231B;
					color: white;
				}
				&:after {
					opacity: 0;
					pointer-events: none;
				}
			}
		}
		span {
			display: flex;
			align-items: center;
			justify-content: center;
			border: 4px solid #4B4F54;
			background: white;
			border-radius: 100px;
			padding: 15px 15px 15px;
			position: relative;
			z-index: 1;
			width: 100%;
			@include smooth-transition;
			height: 121px;
			@include mq {
				border-width: 2px;
				height: 75px;
			}
		}
		&:after {
			position: absolute;
			content: '';
			width: 95px;
			height: 12px;
			top: 47%;
			right: 0;
			background: url("../img/common/ico-arr-btn.png") no-repeat;
			background-size: 100% auto;
			z-index: 2;
			transform: translateY(-50%);
			@include smooth-transition;
			@include mq {
				width: 47px;
				height: 6px;
				top: 48%;
				right: -18px;
			}
		}
	}
	&-comp02 {
		display: flex;
		font-family: $font-PhosphateSolid;
		padding-right: 52px;
		position: relative;
		letter-spacing: 4px;
		width: 100%;
		max-width: 450px;
		@include mq {
			padding-right: 0;
			letter-spacing: 2.2px;
			max-width: 250px;
		}
		&:after {
			position: absolute;
			content: '';
			width: 95px;
			height: 12px;
			top: 46%;
			right: 10px;
			transform: translateY(-50%);
			background: url("../img/common/ico-arr-red-02.png") no-repeat;
			background-size: 100% auto;
			@include mq {
				width: 47px;
				height: 10px;
				top: 49%;
				right: 6px;
			}
		}
		span {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			color: #E2231B;
			@include font-size(30);
			width: 100%;
			max-width: 398px;
			height: 124px;
			border-radius: 100px;
			padding: 0 8px 2px 0;
			@include mq(xl) {
				@include font-size(24);
			}
			@include mq {
				@include font-size(16);
				height: 60px;
			}
			&:after {
				position: absolute;
				content: '';
				border: 4px solid #E2231B;
				width: 32px;
				height: 32px;
				right: -52px;
				top: 47%;
				transform: translateY(-50%);
				border-radius: 50%;
				@include smooth-transition;
				@include mq {
					width: 16px;
					height: 16px;
					border-width: 2px;
					right: 0;
				}
			}
		}
		&:hover {
			opacity: 1;
			span {
				&:after {
					@include mq(md-min) {
						width: calc(100% - 8px);
						height: calc(100% - 8px);
						right: 0;
						top: 50%;
						border-radius: 100px;
					}
				}
			}
		}
		&__r {
			margin-left: auto;
		}
	}
	&-comp03 {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		border: 3px solid #c4bba8;
		font-weight: 900;
		@include font-size(24);
		line-height: 1;
		color: #ab9977;
		width: 320px;
		height: 70px;
		border-radius: 52px;
		letter-spacing: 2px;
		position: relative;
		@include mq(){
			@include font-size(16);
			max-width: 180px;
			height: 50px;
			letter-spacing: 1px;
			border: 2px solid #c4bba8;
		}
		&:after{
			content: "";
			width: 94px;
			height: 12px;
			background: url("../img/common/ico-arr-btn-02.png")no-repeat;
			background-size: 100%;
			position: absolute;
			right: -33px;
			top: 22px;
			@include mq(){
				width: 45px;
				height: 8px;
				right: -22px;
				top: 17px;
			}
		}
		span{
			position: relative;
			z-index: 1;
			@include mq(){
				top: -2px;
			}
		}
	}
	&-comp04 {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		max-width: 394px;
		height: 120px;
		border: 4px solid #4B4F54;
		border-radius: 100px;
		@include font-size(30);
		font-weight: bold;
		background: white;
		color: #4B4F54;
		font-family: $font-notoJp;
		position: relative;
		@include smooth-transition;
		outline: none;
		cursor: pointer;
		@include mq(xl) {
			height: 100px;
			@include font-size(24);
		}
		@include mq {
			height: 75px;
			@include font-size(16);
			border-width: 2px;
		}
		&:before {
			position: absolute;
			content: '';
			width: 95px;
			height: 12px;
			background-image: url("../img/common/ico-arr-btn.png");
			background-repeat: no-repeat;
			background-size: 100% auto;
			top: 50%;
			right: 0;
			transform: translate(50%, -50%);
			@include mq {
				width: 48px;
				height: 6px;
				top: 48%;
				right: 2px;
			}
		}
		&:hover {
			opacity: 1;
			@include mq(md-min) {
				color: white;
				border-color: #E2231B;
				background: #E2231B;
				&:before {
					display: none;
				}
			}
		}
		&--gray {
			color: #ADADAD;
			border-color: #ADADAD;
			&:before {
				background-image: url("../img/common/ico-arr-gray.png");
				background-repeat: no-repeat;
				right: auto;
				left: 0;
				transform: translate(-50%, -50%);
			}
			&:hover {
				@include mq(md-min) {
					background: white;
					color: #4B4F54;
					border-color: #4B4F54;
				}
			}
		}
	}
}
button.btn-comp01, input.btn-comp01 {
	padding-top: 0;
	padding-bottom: 0;
	font-family: $font-notoJp;
}

//sec-common
.sec-common {
	position: relative;
	z-index: 1;
	padding-bottom: 151px;
	@include mq {
		padding-bottom: 44px;
	}
	&:before {
		position: absolute;
		content: '';
		top: 88px;
		bottom: 0;
		width: 100vw;
		background: rgba(221,216,205,0.25);
		z-index: -1;
		border-radius: 95px;
		right: 20.3125%;
		@include mq(lp) {
			top: 60px;
		}
		@include mq {
			top: 31px;
			right: 37px;
			border-radius: 46px;
		}
		@include mq(sm){
			top: vwsp(31);
		}
		@include mq(xs) {
			top: 22px;
		}
	}
	&--r {
		&:before {
			right: auto;
			left: 20.3125%;
			@include mq {
				left: 37px;
			}
		}
	}
}

//common-list
.common-list {
	@include mq(sm) {
		padding-right: 11px;
	}
	&__item {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 85px;
		@include mq(sm) {
			margin-bottom: -25px;
		}
		&:nth-child(2n+2) {
			flex-direction: row-reverse;
			@include mq(sm) {
				.common-list {
					&__img {
						margin-bottom: 27px;
						img {
							max-width: 210px;
							margin-left: auto;
						}
					}
				}
			}
		}
		&:nth-child(2n+1) {
			@include mq(sm) {
				flex-direction:column-reverse;
				.common-list {
					&__img {
						img {
							width: 87%;
						}
					}
					&__content {
						margin-bottom: 27px;
					}
				}
			}
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__img {
		width: 50%;
		overflow: hidden;
		//border-radius: 20px;
		border-radius: vw(20);
		@include mq(sm) {
			width: 100%;
			//max-width: 301px;
			//margin: 0 auto;
			border-radius: 0;
		}
		img {
			display: block;
			object-fit: cover;
			font-family: 'object-fit: cover;';
			width: 100%;
			height: 100%;
		}
	}
	&__content {
		width: 50%;
		margin: 0 auto;
		//max-width: 606px;
		//padding: 0 15px 0 15px;
		max-width: vw(606);
		padding: 0 vw(15);
		@include mq(md-min){
			font-size: vw(32);
		}
		@include mq(sm) {
			width: 100%;
			max-width: 100%;
			padding: 0;
		}
		p {
			//margin-bottom: 42px;
			//@include mq(xl) {
			//	margin-bottom: 30px;
			//}
			margin-bottom: vw(42);
			@include mq {
				margin-bottom: 12px;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&--r {
		@include mq(sm) {
			padding-right: 0;
			padding-left: 23px;
		}
		.common-list {
			&__item {
				margin-bottom: 120px;
				@include mq(lp) {
					margin-bottom: 80px;
				}
				@include mq {
					margin-bottom: 46px;
				}
				&:nth-child(2n+2) {
					flex-direction: row;
				}
				&:nth-child(2n+1) {
					flex-direction: row-reverse;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
			&__content {
				@include mq(sm) {
					margin-bottom: 0 !important;
				}
			}
			&__img {
				@include mq(sm) {
					margin: 0 !important;
					img {
						max-width: 100% !important;
					}
				}
			}
		}
	}
}




//checkbox
.checkbox {
	display: inline-flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;
	position: relative;
	padding-left: 63px;
	cursor: pointer;
	user-select: none;
	@include font-size(30);
	font-weight: bold;
	letter-spacing: 2px;
	@include mq(xl) {
		@include font-size(24);
	}
	@include mq {
		@include font-size(16);
		letter-spacing: 1px;
		padding-left: 32px;
	}
	a {
		text-decoration: underline;
		text-underline-position: under;
		text-decoration-color: #D2CBBD;
	}
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		&:checked ~ .checkmark:after {
			display: block;
		}
	}
	.checkmark {
		position: absolute;
		top: 5px;
		left: 0;
		height: 42px;
		width: 42px;
		border: 4px solid #C4BBA8;
		border-radius: 7px;
		@include mq {
			border-width: 2px;
			width: 21px;
			height: 21px;
		}
		&:after {
			content: "";
			position: absolute;
			display: none;
			left: 11px;
			top: 2px;
			width: 10px;
			height: 20px;
			border: solid #C4BBA8;
			border-width: 0 3px 3px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
			@include mq {
				left: 6px;
				top: 1px;
				width: 5px;
				height: 10px;
				border-width: 0 2px 2px 0;
			}
		}
	}
}

// form-control
.form-control {
	background: #F5F3F0;
	border: none;
	width: 100%;
	height: 100px;
	display: block;
	appearance: none;
	border-radius: 6px;
	margin-bottom: 8px;
	resize: vertical;
	outline: none;
	padding: 15px;
	font-weight: normal;
	color: #4B4F54;
	@include font-size(20);
	@include mq {
		@include font-size(16);
		height: 60px;
		margin-bottom: 4px;
		padding: 10px;
	}
	&:last-child {
		margin-bottom: 0;
	}
}
textarea.form-control {
	height: 320px;
	@include mq {
		height: 180px;
	}
}
.btn-comp01[disabled] {
	cursor: not-allowed;
	pointer-events: none;
}
p.form-control {
	padding: 20px 0 20px 42px;
	background: transparent;
	min-height: 100px;
	height: 100%;
	@include mq {
		padding: 0;
		min-height: auto;
	}
}

.en{
	font-family: $font-PhosphateSolid;
}