@charset "utf-8";

/* ====================================================
reset style
==================================================== */
html {
	overflow-y: auto;
	overflow-x: hidden;
	font-size: 10px; /* Base font size, change to 14px or 16px if needed */
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	&.no-scroll {
		overflow: hidden;
	}
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
	margin: 0;
	padding: 0;
}

*{
	box-sizing: border-box;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

fieldset,img {
	border: 0;
}

img {
	vertical-align: middle;
	max-width: 100%;
}

address, caption, cite, code, dfn, var {
	font-style: normal;
	font-weight: normal;
}

li {
	list-style: none;
}

caption, th {
	text-align: left;
}

h1, h2, h3, h4, h5, h6 {
	font-size: 100%;
	font-weight: normal;
}

q:before, q:after { 
	content: '';
}
abbr, acronym {
	border: 0;
	font-variant: normal;
}

sup {
	vertical-align: text-top;
}
sub {
	vertical-align: text-bottom;
}

input, textarea, select {
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
}


/*to enable resizing for IE*/
input, textarea, select {
	*font-size:100%;
}
/*because legend doesn't inherit in IE */
legend {
	color: #000;
}
del, ins {
	text-decoration:none;
}
main{
	display: block;
}
section{
	position: relative;
}